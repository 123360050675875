import update from 'immutability-helper';
import {
  BANNERS_PROGRESS,
  BANNERS_SUCCESS,
  BANNERS_FAILED,
  BANNERS_GET_LIST_PROGRESS,
  BANNERS_GET_LIST_SUCCESS,
  BANNERS_GET_LIST_FAILED,
  BANNERS_FORM_SUBMIT_PROGRESS,
  BANNERS_FORM_SUBMIT_SUCCESS,
  BANNERS_FORM_SUBMIT_FAILED,
  BANNERS_GET_BANNER_PROGRESS,
  BANNERS_GET_BANNER_SUCCESS,
  BANNERS_GET_BANNER_FAILED,
  BANNERS_CLEAR_STATE,
  BANNERS_CLEAR_FLASH_MESSAGE,
} from '../actions/bannersAction';

const initialState = {
  loadListStatus: '', // progress/success/failed
  loadActiveStatus: '', // progress/success/failed
  submitStatus: '', // progress/success/failed
  asyncStatus: '', // progress/success/failed
  list: [],
  pagination: {},
  active: {},
  asyncData: {},
  flash: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BANNERS_GET_LIST_PROGRESS: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: BANNERS_PROGRESS },
        },
      );
    }
    case BANNERS_GET_LIST_SUCCESS: {
      return update(
        state, {
          list: { $set: action.payload.list },
          pagination: { $set: action.payload.pagination },
          loadListStatus: { $set: BANNERS_SUCCESS },
        },
      );
    }
    case BANNERS_GET_LIST_FAILED: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: BANNERS_FAILED },
        },
      );
    }
    case BANNERS_FORM_SUBMIT_PROGRESS: {
      return update(
        state,
        { submitStatus: { $set: BANNERS_PROGRESS } },
      );
    }
    case BANNERS_FORM_SUBMIT_SUCCESS: {
      return update(
        state,
        {
          flash: { $set: action.payload },
          submitStatus: { $set: BANNERS_SUCCESS },
        },
      );
    }
    case BANNERS_FORM_SUBMIT_FAILED: {
      return update(
        state,
        { submitStatus: { $set: BANNERS_FAILED } },
      );
    }
    case BANNERS_GET_BANNER_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: BANNERS_PROGRESS },
        },
      );
    }
    case BANNERS_GET_BANNER_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.banner },
          loadActiveStatus: { $set: BANNERS_SUCCESS },
        },
      );
    }
    case BANNERS_GET_BANNER_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: BANNERS_FAILED },
        },
      );
    }
    case BANNERS_CLEAR_STATE: {
      return update(
        state,
        {
          loadListStatus: { $set: '' },
          loadActiveStatus: { $set: '' },
          submitStatus: { $set: '' },
          asyncStatus: { $set: '' },
          list: { $set: [] },
          pagination: { $set: {} },
          active: { $set: {} },
          asyncData: { $set: {} },
        },
      );
    }
    case BANNERS_CLEAR_FLASH_MESSAGE: {
      return update(
        state, {
          flash: { $set: '' },
        },
      );
    }
    default:
      return state;
  }
}
