import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import MaraphonTaskTablePage from '../../../MaraphonTasks/MaraphonTaskTable';
import MaraphonTaskCreatePage from '../../../MaraphonTasks/MaraphonTaskCreate/index';
import MaraphonTaskEditPage from '../../../MaraphonTasks/MaraphonTaskEdit/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/maraphon_tasks" exact component={MaraphonTaskTablePage} />
    <ProtectedRoute path="/maraphon_tasks/create" component={MaraphonTaskCreatePage} />
    <ProtectedRoute path="/maraphon_tasks/:taskId" exact component={MaraphonTaskEditPage} />
  </Switch>
);
