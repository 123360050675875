import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, FieldArray, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import RenderChecklist from '../../components/RenderChecklist';
import renderField from '../../../../shared/components/form/TextField';
import validate from '../../validate';

class WizardFormSix extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit, previousPage } = this.props;

    return (
      <form className="form form--horizontal wizard__form wizard__form__lg" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Составьте и отсортируйте отрицательные факторы</h3>
        <div className="form__form-group">
          <span className="form__form-group-label">Заглавие</span>
          <div className="form__form-group-field">
            <Field
              name="dangerlist_header"
              component={renderField}
              type="text"
              placeholder="Заглавие"
            />
          </div>
        </div>
        <FieldArray name="dangerlist" component={RenderChecklist} />
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}>Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Следующий шаг</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'themes_create_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(WizardFormSix);
