// require('dotenv').config();
let apiUrl;
let schoolUrl;
const { protocol, hostname } = window.location;

if (hostname === 'localhost') {
  apiUrl = `${protocol}//${hostname}:4000`;
  schoolUrl = `${protocol}//${hostname}:5000/`;
} else if (hostname === 'admin.karpachoff.academy') {
  apiUrl = 'https://api.karpachoff.academy/';
  schoolUrl = 'https://karpachoff.academy/';
} else {
  apiUrl = 'https://api.karpachofff.com/';
  schoolUrl = 'https://karpachoff.academy/';
}

// let apiUrl = `${process.env.API_PROTOCOL}://${process.env.API_HOSTNAME}/`;
//
// if (process.env.API_PORT) {
//   apiUrl += process.env.API_PORT;
// }

const config = {
  urls: {
    api: apiUrl,
    school: schoolUrl,
  },
};

export default config;
