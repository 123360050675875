const validate = (values) => {
  const errors = {};
  const requiredErrorMessage = 'Это поле является обязательным';

  if (!values.name) {
    errors.name = requiredErrorMessage;
  } else if (values.name.length < 3) {
    errors.name = 'Введите не менее 3-х символов';
  }

  if (!values.stream) {
    errors.stream = requiredErrorMessage;
  }

  if (!values.start) {
    errors.start = requiredErrorMessage;
  }

  if (!values.end) {
    errors.end = requiredErrorMessage;
  }

  return errors;
};

export default validate;
