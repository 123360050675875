import React, { PureComponent } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import PropTypes from 'prop-types';

registerLocale('ru', ru);

class DateTimePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }),
  };

  static defaultProps = {
    meta: null,
  };

  constructor() {
    super();
    this.state = {
      startDate: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    /* eslint react/prop-types: 0 */
    const { value } = this.props;

    if (value instanceof Date) {
      this.setState({ startDate: value });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { value } = nextProps;

    if (value instanceof Date) {
      this.setState({ startDate: value });
    }
  }

  handleChange = (date) => {
    const { onChange } = this.props;
    this.setState({
      startDate: date,
    });
    onChange(date);
  };

  render() {
    const { meta } = this.props;
    const { startDate } = this.state;

    return (
      <div className="date-picker">
        <DatePicker
          timeFormat="HH:mm"
          className="form__form-group-datepicker"
          selected={startDate}
          onChange={this.handleChange}
          showTimeSelect
          dateFormat="dd.MM.YYYY HH:mm"
          locale="ru"
          dropDownMode="select"
        />
        {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
      </div>
    );
  }
}

const renderDateTimePickerField = (props) => {
  const { input, meta } = props;
  return <DateTimePickerField {...input} meta={meta} />;
};

renderDateTimePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderDateTimePickerField.defaultProps = {
  meta: null,
};

export default renderDateTimePickerField;
