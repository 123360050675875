import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import TagTablePage from '../../../Tags/TagTable/index';
import TagCreatePage from '../../../Tags/TagCreate/index';
import TagEditPage from '../../../Tags/TagEdit/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/tags" exact component={TagTablePage} />
    <ProtectedRoute path="/tags/create" component={TagCreatePage} />
    <ProtectedRoute path="/tags/:tagId" exact component={TagEditPage} />
  </Switch>
);
