const validate = (values) => {
  const errors = {};
  const requiredErrorMessage = 'Это поле является обязательным';

  if (!values.name) {
    errors.name = requiredErrorMessage;
  } else if (values.name.length < 2) {
    errors.name = 'Введите не менее 2-х символов';
  }

  if (values.type === 'sublist' || values.type === 'done') {
    if (!values.parent) {
      errors.parent = requiredErrorMessage;
    }
  }

  if (values.type === 'list' || values.type === 'sublist' || values.type === 'mix') {
    if (!values.min || values.min < 1) {
      errors.min = requiredErrorMessage;
    }
  }

  if (values.type === 'radio' || values.type === 'sentences' || values.type === 'interview' || values.type === 'mix') {
    if (!values.options || !values.options.length) {
      errors.options = { _error: 'Опции должны содержать минимум одну опцию' };
    } else {
      const optionsArrayErrors = [];

      values.options.forEach((option, optionIndex) => {
        const optionErrors = {};

        if (typeof option.name === 'undefined') {
          optionErrors.name = requiredErrorMessage;
          optionsArrayErrors[optionIndex] = optionErrors;
        }
      });

      if (optionsArrayErrors.length) {
        errors.options = optionsArrayErrors;
      }
    }
  }

  if (values.type === 'interview' || values.type === 'mix') {
    if (!values.options2 || !values.options2.length) {
      errors.options2 = { _error: 'Опции должны содержать минимум одну опцию' };
    } else {
      const optionsArrayErrors = [];

      values.options2.forEach((option, optionIndex) => {
        const optionErrors = {};

        if (typeof option.name === 'undefined') {
          optionErrors.name = requiredErrorMessage;
          optionsArrayErrors[optionIndex] = optionErrors;
        }
      });

      if (optionsArrayErrors.length) {
        errors.options2 = optionsArrayErrors;
      }
    }
  }

  return errors;
};

export default validate;
