import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import PlayIcon from 'mdi-react/PlayIcon';
import { formValueSelector } from 'redux-form';
import store from '../../containers/App/store';
import UrlValidator from '../classes/UrlValidator';

export default class PopupAudioPlayer extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    reduxFormName: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    fieldSubName: PropTypes.string.isRequired,
    fieldIndex: PropTypes.number.isRequired,
  };

  static defaultProps = {
    title: '',
    colored: false,
    header: false,
  };

  constructor() {
    super();
    this.state = {
      modal: false,
      src: '',
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const { modal } = this.state;
    let src = '';

    if (!modal.modal) {
      const {
        reduxFormName, fieldName, fieldSubName, fieldIndex,
      } = this.props;
      const selector = formValueSelector(reduxFormName);
      const field = selector(store.getState(), fieldName);

      if (UrlValidator.isValidUrl(field[fieldIndex][fieldSubName])) {
        src = field[fieldIndex][fieldSubName];
      }
    }

    this.setState(prevState => ({ modal: !prevState.modal, src }));
  }

  render() {
    const {
      color, title, colored, header,
    } = this.props;
    const { modal, src } = this.state;
    let Icon;

    switch (color) {
      case 'primary':
        Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
        break;
      case 'success':
        Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
        break;
      case 'warning':
        Icon = <span className="lnr lnr-flag modal__title-icon" />;
        break;
      case 'danger':
        Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <Fragment>
        <div className="form__form-group-icon">
          <PlayIcon color={color} onClick={this.toggle} />
        </div>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          className={`modal-dialog--${color} ${modalClass}`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body pr-4 pl-4">
            {src ? (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <audio style={{ width: '100%' }} controls crossOrigin="anonymous" src={src} />
            ) : (
              <p className="center-text red-text">Неверный URL адрес</p>
            )}
          </div>
          <ButtonToolbar className="modal__footer">
            <Button onClick={this.toggle}>Закрыть</Button>
          </ButtonToolbar>
        </Modal>
      </Fragment>
    );
  }
}
