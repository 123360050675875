import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderField from '../../../../shared/components/form/TextField';
import Alert from '../../../../shared/components/Alert';
import { required, minLength6, email } from '../../fieldLevelValidate';
import validate from '../../validate';
import asyncValidate from '../../asyncValidate';

const localInfo = (
  <p>
    <span className="bold-text">Важная информация!</span>
    <br />
    Этот пользователь был авторизован <span className="bold-text">через логин и пароль</span>.
    <br />
    Если вы хотите изменить этому пользователю пароль, тогда введите&nbsp;
    <span className="bold-text">НОВЫЙ</span> пароль в поле &quot;Пароль&quot;.
    <br />
    Если вы хотите оставить прежний пароль, оставьте поле &quot;Пароль&quot; пустым.
  </p>
);

class WizardFormOne extends PureComponent {
  static propTypes = {
    authorizationMethod: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit, previousPage, authorizationMethod } = this.props;

    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Заполните данные для авторизации</h3>
        <Alert color="info" button={false} style={{ width: '100%' }} className="alert--bordered" icon>
          {authorizationMethod === 'local' && localInfo}
        </Alert>
        <div className="form__form-group">
          <span className="form__form-group-label">E-mail</span>
          <div className="form__form-group-field">
            <Field
              name="email"
              component={renderField}
              type="text"
              placeholder="E-mail"
              validate={[required, email]}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Пароль</span>
          <div className="form__form-group-field">
            <Field
              name="password"
              component={renderField}
              type="text"
              placeholder="Пароль"
              validate={[minLength6]}
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}>Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Следующий шаг</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'users_edit_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: false,
  validate,
  asyncValidate,
  asyncBlurFields: ['email'],
})(WizardFormOne);
