/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import {
  WEBINARS_SUCCESS,
  getWebinarsList as getWebinarsListAction,
  webinarClearState as webinarClearStateAction,
  webinarClearFlashMessage as webinarClearFlashMessageAction,
} from '../../../../redux/actions/webinarsActions';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { FullWideNotification } from '../../../../shared/components/Notification';
import './style.css';

const { SearchBar } = Search;
let page = 1;

class WebinarTable extends PureComponent {
  static propTypes = {
    getWebinarsList: PropTypes.func.isRequired,
    webinarClearState: PropTypes.func.isRequired,
    webinarClearFlashMessage: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    webinarsList: PropTypes.arrayOf(
      PropTypes.objectOf(PropTypes.any),
    ).isRequired,
    loadStatus: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.objectOf(PropTypes.any),
    ),
    flashMessage: PropTypes.string.isRequired,
  };

  static defaultProps = {
    columns: [{
      dataField: 'row',
      text: '#',
      formatter: (content, record, index) => <div style={{ width: '20px' }}>{(index + 1) + 10 * page - 10}</div>,
    }, {
      dataField: 'name',
      text: 'Название вебинара',
      sort: true,
    }, {
      dataField: 'date.start',
      text: 'Начало вебинара',
      sort: true,
      formatter: cell => <Moment format="DD.MM.YYYY в HH:mm">{cell}</Moment>,
    }, {
      dataField: 'date.end',
      text: 'Окончание вебинара',
      sort: true,
      formatter: cell => <Moment format="DD.MM.YYYY в HH:mm">{cell}</Moment>,
    }, {
      dataField: 'createdAt',
      text: 'Дата создания',
      sort: true,
      formatter: cell => <Moment format="DD.MM.YYYY в HH:mm">{cell}</Moment>,
    }, {
      dataField: 'id',
      text: 'Действия',
      formatter: cell => <Link to={`/webinars/${cell}`}>Редактировать</Link>,
    }],
  };

  componentDidMount() {
    const { getWebinarsList } = this.props;
    getWebinarsList();
  }

  componentWillReceiveProps(nextProps) {
    const { flashMessage, webinarClearFlashMessage } = nextProps;

    if (flashMessage && flashMessage.length) {
      this.show('success', flashMessage);
      setTimeout(webinarClearFlashMessage, 2000);
    }
  }

  componentWillUnmount() {
    const { webinarClearState } = this.props;
    webinarClearState();
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  render() {
    const { loadStatus, webinarsList, columns } = this.props;

    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      totalSize: webinarsList.length,
      onPageChange: (currentPage) => { page = currentPage; },
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <PaginationListStandalone {...paginationProps} />
        <ToolkitProvider
          bootstrap4
          keyField="id"
          columns={columns}
          data={webinarsList}
          search
        >
          {
            toolkitprops => (
              <div>
                <SearchBar {...toolkitprops.searchProps} />
                <BootstrapTable
                  classes="my-table"
                  striped
                  hover
                  {...toolkitprops.baseProps}
                  {...paginationTableProps}
                />
              </div>
            )
          }
        </ToolkitProvider>
        <PaginationListStandalone {...paginationProps} />
      </div>
    );

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <div className="text__wrap">
                <h5 className="bold-text">Список всех вебинаров</h5>
                <h5 className="subhead">Здесь вы можете добавить или редактировать вебинары</h5>
              </div>
              <button type="button" className="btn btn-primary">
                <Link to="/webinars/create">Добавить</Link>
              </button>
            </div>
          </CardBody>
          <CardBody>
            {loadStatus === WEBINARS_SUCCESS ? (
              <PaginationProvider
                pagination={paginationFactory(options)}
              >
                {contentTable}
              </PaginationProvider>
            ) : null }
            <h4 className="subhead">Всего в базе находится {webinarsList.length} вебинаров.</h4>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  webinarsList: state.webinars.list,
  loadStatus: state.webinars.loadListStatus,
  flashMessage: state.webinars.flash,
});

const mapDispatchToProps = dispatch => ({
  getWebinarsList: () => dispatch(getWebinarsListAction()),
  webinarClearState: () => dispatch(webinarClearStateAction()),
  webinarClearFlashMessage: () => dispatch(webinarClearFlashMessageAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(withRouter(WebinarTable)));
