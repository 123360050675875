import moment from 'moment';
import { startSubmit, stopSubmit } from 'redux-form';
import ws from '../../ws';
import getToken from './getToken';
import PaginationPageConverter from '../../shared/classes/PaginationPageConverter';

const TAGS_PROGRESS = 'progress';
const TAGS_SUCCESS = 'success';
const TAGS_FAILED = 'failed';

const TAGS_GET_LIST_PROGRESS = 'TAGS_GET_LIST_PROGRESS';
const TAGS_GET_LIST_SUCCESS = 'TAGS_GET_LIST_SUCCESS';
const TAGS_GET_LIST_FAILED = 'TAGS_GET_LIST_FAILED';
const TAGS_FORM_SUBMIT_PROGRESS = 'TAGS_FORM_SUBMIT_PROGRESS';
const TAGS_FORM_SUBMIT_SUCCESS = 'TAGS_FORM_SUBMIT_SUCCESS';
const TAGS_FORM_SUBMIT_FAILED = 'TAGS_FORM_SUBMIT_FAILED';
const TAGS_GET_TAG_PROGRESS = 'TAGS_GET_TAG_PROGRESS';
const TAGS_GET_TAG_SUCCESS = 'TAGS_GET_TAG_SUCCESS';
const TAGS_GET_TAG_FAILED = 'TAGS_GET_TAG_FAILED';
const TAGS_CLEAR_STATE = 'TAGS_CLEAR_STATE';

const TAGS_CLEAR_FLASH_MESSAGE = 'TAGS_CLEAR_FLASH_MESSAGE';

const tagGetListProgress = () => ({
  type: TAGS_GET_LIST_PROGRESS,
});

const tagGetListSuccess = payload => ({
  type: TAGS_GET_LIST_SUCCESS,
  payload,
});

const tagGetListFailed = () => ({
  type: TAGS_GET_LIST_FAILED,
});

const tagStartFormSubmit = () => ({
  type: TAGS_FORM_SUBMIT_PROGRESS,
});

const tagFormSubmitSuccess = payload => ({
  type: TAGS_FORM_SUBMIT_SUCCESS,
  payload,
});

const tagFormSubmitFailed = () => ({
  type: TAGS_FORM_SUBMIT_FAILED,
});

const tagGetTagProgress = () => ({
  type: TAGS_GET_TAG_PROGRESS,
});

const tagGetTagSuccess = payload => ({
  type: TAGS_GET_TAG_SUCCESS,
  payload,
});

const tagGetTagFailed = () => ({
  type: TAGS_GET_TAG_FAILED,
});

const tagClearState = () => ({
  type: TAGS_CLEAR_STATE,
});

const convertListToTable = data => new PaginationPageConverter(data, doc => ({
  id: doc.id,
  name: doc.name,
  createdAt: moment(doc.createdAt).locale('ru').format('DD MMM YY г., HH:mm'),
})).getConvertedData();

const getTagsList = params => (dispatch) => {
  const token = getToken();
  dispatch(tagGetListProgress());

  ws.emit('api/academy/tags/list', { token, payload: params }, (data) => {
    const { status, payload } = data;
    let action;

    if (status === 'ok') {
      action = tagGetListSuccess(convertListToTable(payload.data));
    } else {
      action = tagGetListFailed();
    }

    dispatch(action);
  });
};

const convertSetTagToDB = data => ({
  name: data.name.replace(/\s{2,}/g, ' '),
});

const createTag = (data, dispatch, props) => {
  const token = getToken();
  const { form, reset, updateTags } = props;
  const convertedData = convertSetTagToDB(data);

  dispatch(startSubmit(form));
  dispatch(tagStartFormSubmit());

  ws.emit('api/academy/tags/create', {
    // payload: convertedData, token,
    token, payload: { convertedData },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      reset();
      dispatch(stopSubmit(form));

      if (form !== 'questions_tags_create_form') {
        dispatch(tagFormSubmitSuccess('Новый тег был успешно создан.'));
      } else {
        dispatch(tagFormSubmitSuccess(''));
        updateTags();
      }
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(tagFormSubmitFailed());
    }
  });
};

const convertDBTagToEdit = (payload) => {
  const { tag: tagFromDB } = payload;

  const tag = {
    id: tagFromDB.id,
    name: tagFromDB.name,
  };

  return { tag };
};

const getTag = tagId => async (dispatch) => {
  const token = getToken();
  dispatch(tagGetTagProgress());

  ws.emit('api/academy/tags/getById', {
    // tagId, token,
    token, payload: { tagId },
  }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = tagGetTagSuccess(convertDBTagToEdit(payload));
    } else {
      action = tagGetTagFailed();
    }

    dispatch(action);
  });
};

const updateTag = (data, dispatch, props) => {
  const token = getToken();
  const { id } = data;
  const { form } = props;
  const convertedData = convertSetTagToDB(data);

  dispatch(startSubmit(form));
  dispatch(tagStartFormSubmit());

  ws.emit('api/academy/tags/updateById', {
    // tagId: id, payload: convertedData, token,
    token, payload: { tagId: id, convertedData },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(stopSubmit(form));
      dispatch(tagFormSubmitSuccess('Тег был успешно отредактирован.'));
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(tagFormSubmitFailed());
    }
  });
};

const tagClearFlashMessage = () => ({
  type: TAGS_CLEAR_FLASH_MESSAGE,
});

export {
  TAGS_PROGRESS,
  TAGS_SUCCESS,
  TAGS_FAILED,
  TAGS_GET_LIST_PROGRESS,
  TAGS_GET_LIST_SUCCESS,
  TAGS_GET_LIST_FAILED,
  TAGS_FORM_SUBMIT_PROGRESS,
  TAGS_FORM_SUBMIT_SUCCESS,
  TAGS_FORM_SUBMIT_FAILED,
  TAGS_GET_TAG_PROGRESS,
  TAGS_GET_TAG_SUCCESS,
  TAGS_GET_TAG_FAILED,
  TAGS_CLEAR_STATE,
  TAGS_CLEAR_FLASH_MESSAGE,
  tagGetListProgress,
  tagGetListSuccess,
  tagGetListFailed,
  tagStartFormSubmit,
  tagFormSubmitSuccess,
  tagFormSubmitFailed,
  tagGetTagProgress,
  tagGetTagSuccess,
  tagGetTagFailed,
  tagClearState,
  getTagsList,
  createTag,
  getTag,
  updateTag,
  tagClearFlashMessage,
};
