import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './styles.module.scss';
import 'moment/locale/ru';

class Dialog extends PureComponent {
  static propTypes = {
    dialog: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  };

  showDate = date => moment(date).locale('ru').calendar(null, {
    sameDay: '[Сегодня, в] LT',
    lastDay: '[Вчера, в] LT',
    sameElse: 'LL',
  });

  showInitials = name => name.split(' ').map(word => word.charAt(0)).join('');

  prepareVideo = (content, videos) => {
    let html = content;

    if (videos && videos.length) {
      for (let i = 0; i < videos.length; i += 1) {
        html = html.replace(
          new RegExp(`%video${i + 1}%`, 'g'),
          `<div class="${styles.video}">${videos[i]}</div>`,
        );
      }
    }

    return html;
  };

  render() {
    const { dialog } = this.props;

    return (
      <div className={styles.dialog}>
        <h3>История переписки</h3>
        <div className={styles.record}>
          {dialog.map(message => (
            <div key={message.question.id} className={styles.messages}>
              <div className={styles.question}>
                {message.question.user.photo ? (
                  <div className={styles.photo}>
                    <img src={message.question.user.photo} alt={message.question.user.name} />
                  </div>
                ) : (
                  <div className={styles.initials}>{this.showInitials(message.question.user.name)}</div>
                )}
                <div className={styles.body}>
                  <p className={styles.name}>
                    {message.question.user.name}
                    <span className={styles.bull}>&bull;</span>
                    <span className={styles.date}>{this.showDate(message.question.date)}</span>
                  </p>
                  <div
                    className={styles.message}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: message.question.content,
                    }}
                  />
                </div>
              </div>
              {message.answer.id !== null ? (
                <div className={styles.answer}>
                  {message.answer.user.photo ? (
                    <div className={styles.photo}>
                      <img src={message.answer.user.photo} alt={message.answer.user.name} />
                    </div>
                  ) : (
                    <div className={styles.initials}>{this.showInitials(message.answer.user.name)}</div>
                  )}
                  <div className={styles.body}>
                    <p className={styles.name}>
                      {message.answer.user.name}
                      <span className={styles.bull}>&bull;</span>
                      <span className={styles.date}>{this.showDate(message.answer.date)}</span>
                    </p>
                    <div
                      className={styles.message}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: this.prepareVideo(message.answer.content, message.answer.videos),
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Dialog;
