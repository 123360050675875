import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import UserEditPage from '../../../Prolongation/ProlongationEdit/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/prolongation/:userId" exact component={UserEditPage} />
  </Switch>
);
