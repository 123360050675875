import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import ArrowUpBold from 'mdi-react/ArrowUpBoldIcon';
import ArrowDownBoldIcon from 'mdi-react/ArrowDownBoldIcon';
import renderField from '../../../shared/components/form/TextField';
import renderSelectField from '../../../shared/components/form/Select';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';

const marginTop10 = { width: '100%', display: 'flex', marginTop: '10px' };
const marginTop20 = { width: '100%', display: 'flex', marginTop: '20px' };
const marginTop35 = { width: '100%', display: 'flex', marginTop: '35px' };
const originalTheme = {
  width: '100%',
  display: 'flex',
  backgroundColor: '#70bbfd',
  color: '#ffffff',
  padding: '10px',
  marginBottom: '10px',
};

class RenderTasks extends PureComponent {
  static propTypes = {
    fields: PropTypes.objectOf(PropTypes.any).isRequired,
    options: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
  };

  static moveToUp(fields, index) {
    if (index > 0) {
      fields.swap(index, index - 1);
    }
  }

  static moveToDown(fields, index) {
    if (fields.length - 1 !== index) {
      fields.swap(index, index + 1);
    }
  }

  componentDidMount() {
    const { fields, options } = this.props;
    const { flowTasks } = options;

    if (!fields.length) {
      for (let i = 0; i < flowTasks.length; i += 1) {
        fields.push({
          id: flowTasks[i].value,
          isActive: true,
          isShowQuestions: false,
          original: flowTasks[i].label,
          isHiddenOnShowcase: true,
        });
      }
    }
  }

  render() {
    const { fields, options } = this.props;
    const { dates, packages } = options;
    const { moveToUp, moveToDown } = RenderTasks;

    return (
      <div className="form__form-group">
        {fields.map((task, index) => (
          <div key={index} className="form__form-group">
            <div style={originalTheme}>
              <strong>ТЕМА:</strong>&nbsp; {fields.get(index).original}
            </div>
            <div style={marginTop10}>
              <Field
                name={`${task}.name`}
                component={renderField}
                type="text"
                placeholder="Альтернативное название темы"
              />
              <div className="form__form-group-icon">
                <ArrowUpBold onClick={() => moveToUp(fields, index)} />
              </div>
              <div className="form__form-group-icon">
                <ArrowDownBoldIcon onClick={() => moveToDown(fields, index)} />
              </div>
            </div>
            <div style={marginTop20}>
              <Field
                name={`${task}.day`}
                component={renderSelectField}
                type="text"
                options={dates}
                placeholder="Выберите доступ к теме"
              />
            </div>
            <div style={marginTop35}>
              <Field
                name={`${task}.isActive`}
                component={renderCheckBoxField}
                label="Активная тема"
              />
              <Field
                name={`${task}.isBonuses`}
                component={renderCheckBoxField}
                label="Эта тема является бонусом"
              />
              <Field
                name={`${task}.isShowQuestions`}
                component={renderCheckBoxField}
                label="Отображать вопросы в этой теме"
              />
              <Field
                name={`${task}.isHiddenOnShowcase`}
                component={renderCheckBoxField}
                label="Скрытая на витрине"
              />
            </div>
            <div style={marginTop10}>
              {packages.map(pack => (
                <span key={pack.id}>
                  <Field
                    name={`${task}.package[${pack.id}]`}
                    component={renderCheckBoxField}
                    label={`Пакет ${pack.name}`}
                  />
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default RenderTasks;
