import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import PropTypes from 'prop-types';
import Alert from '../../../../shared/components/Alert';
import renderField from '../../../../shared/components/form/TextField';
import { singIn as singInAction } from '../../../../redux/actions/authAction';
import { AUTH_FAILED, AUTH_AUTHENTICATED } from '../../../../redux/types/authTypes';
import validate from '../validate';

const alertStyle = {
  width: '100%',
  marginBottom: '20px',
};

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitStatus: PropTypes.string.isRequired,
    authStatus: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };

    this.showPassword = this.showPassword.bind(this);
  }

  showPassword(e) {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  }

  render() {
    const {
      handleSubmit, submitting, submitStatus, authStatus,
    } = this.props;
    const { showPassword } = this.state;

    if (authStatus === AUTH_AUTHENTICATED) {
      return <Redirect to="/" />;
    }

    return (
      <form className="form" onSubmit={handleSubmit}>
        {submitStatus === AUTH_FAILED && (
        <Alert color="danger" button={false} style={alertStyle}>
          <p>Неверный логин или пароль!</p>
        </Alert>
        )}
        <div className="form__form-group">
          <span className="form__form-group-label">E-mail</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <EmailIcon />
            </div>
            <Field
              name="email"
              component={renderField}
              type="text"
              placeholder="E-mail"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Пароль</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component={renderField}
              type={showPassword ? 'text' : 'password'}
              placeholder="Пароль"
            />
            <button
              type="button"
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
            ><EyeIcon />
            </button>
          </div>
        </div>
        <div className="account__social" style={{ width: '100%' }}>
          <Button color="primary" type="submit" disabled={submitting}>Войти в административную панель</Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  submitStatus: state.auth.submitStatus,
  authStatus: state.auth.status,
});

const LogInFormComponent = connect(
  mapStateToProps,
  null,
)(LogInForm);

export default reduxForm({
  form: 'login_form', // a unique identifier for this form
  validate,
  onSubmit: singInAction,
})(withTranslation('common')(withRouter(LogInFormComponent)));
