const checkTaskError = (tasks) => {
  if (tasks && tasks.length) {
    const tasksArrayErrors = [];

    tasks.forEach((task, taskIndex) => {
      const taskErrors = {};

      if (typeof task.task === 'undefined') {
        taskErrors.task = 'Это поле является обязательным';
        tasksArrayErrors[taskIndex] = taskErrors;
      }
    });

    if (tasksArrayErrors.length) {
      return tasksArrayErrors;
    }
  }

  return {};
};

const checkCheckListError = (checkLists) => {
  if (checkLists && checkLists.length) {
    const checklistArrayErrors = [];

    checkLists.forEach((checklist, checklistIndex) => {
      const checklistErrors = {};

      if (!checklist.header) {
        checklistErrors.header = 'Это поле является обязательным';
        checklistArrayErrors[checklistIndex] = checklistErrors;
      }

      if (checklist && checklist.contents && checklist.contents.length) {
        const checklistContentArrayErrors = [];

        checklist.contents.forEach((content, contentIndex) => {
          if (typeof content.content === 'undefined') {
            checklistContentArrayErrors[contentIndex] = { content: 'Это поле является обязательным' };
          }
        });

        if (checklistContentArrayErrors.length) {
          checklistErrors.contents = checklistContentArrayErrors;
          checklistArrayErrors[checklistIndex] = checklistErrors;
        }
      } else {
        checklistErrors.contents = { _error: 'Вы должны добавить хотя бы один пункт' };
        checklistArrayErrors[checklistIndex] = checklistErrors;
      }
    });

    if (checklistArrayErrors.length) {
      return checklistArrayErrors;
    }
  }

  return {};
};

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Это поле является обязательным';
  } else if (values.name.length < 3) {
    errors.name = 'Введите не менее 3-х символов';
  }

  if (!values.header) {
    errors.header = 'Это поле является обязательным';
  }

  if (!values.list) {
    errors.list = 'Это поле является обязательным';
  }

  if (values.objectives && values.objectives.length) {
    const objectivesArrayErrors = [];

    values.objectives.forEach((objective, objectiveIndex) => {
      const objectiveErrors = {};

      if (!objective.name) {
        objectiveErrors.name = 'Это поле является обязательным';
        objectivesArrayErrors[objectiveIndex] = objectiveErrors;
      }
    });

    if (objectivesArrayErrors.length) {
      errors.objectives = objectivesArrayErrors;
    }
  }

  errors.tasks = checkTaskError(values.tasks);
  errors.extra_tasks = checkTaskError(values.extra_tasks);
  errors.checklist = checkCheckListError(values.checklist);
  errors.dangerlist = checkCheckListError(values.dangerlist);

  if (values.attentions && values.attentions.length) {
    const attentionsArrayErrors = [];

    values.attentions.forEach((objective, objectiveIndex) => {
      const objectiveErrors = {};

      if (!objective.header) {
        objectiveErrors.header = 'Это поле является обязательным';
      }

      if (!objective.content) {
        objectiveErrors.content = 'Это поле является обязательным';
      }

      if (Object.keys(objectiveErrors).length) {
        attentionsArrayErrors[objectiveIndex] = objectiveErrors;
      }
    });

    if (attentionsArrayErrors.length) {
      errors.attentions = attentionsArrayErrors;
    }
  }

  return errors;
};

export default validate;
