import update from 'immutability-helper';
import {
  MATERIALS_PROGRESS,
  MATERIALS_SUCCESS,
  MATERIALS_FAILED,
  MATERIALS_GET_LIST_PROGRESS,
  MATERIALS_GET_LIST_SUCCESS,
  MATERIALS_GET_LIST_FAILED,
  MATERIALS_FORM_SUBMIT_PROGRESS,
  MATERIALS_FORM_SUBMIT_SUCCESS,
  MATERIALS_FORM_SUBMIT_FAILED,
  MATERIALS_GET_MATERIAL_PROGRESS,
  MATERIALS_GET_MATERIAL_SUCCESS,
  MATERIALS_GET_MATERIAL_FAILED,
  MATERIALS_PREVIEW_SUBMIT_PROGRESS,
  MATERIALS_PREVIEW_SUBMIT_SUCCESS,
  MATERIALS_PREVIEW_SUBMIT_FAILED,
  MATERIALS_CLEAR_STATE,
  MATERIALS_CLEAR_FLASH_MESSAGE,
} from '../actions/materialsAction';

const initialState = {
  loadListStatus: '', // progress/success/failed
  loadActiveStatus: '', // progress/success/failed
  submitStatus: '', // progress/success/failed
  previewStatus: '', // progress/success/failed
  asyncStatus: '', // progress/success/failed
  list: [],
  pagination: {},
  active: {},
  asyncData: {},
  previewId: '',
  flash: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MATERIALS_GET_LIST_PROGRESS: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: MATERIALS_PROGRESS },
        },
      );
    }
    case MATERIALS_GET_LIST_SUCCESS: {
      return update(
        state, {
          list: { $set: action.payload.list },
          pagination: { $set: action.payload.pagination },
          loadListStatus: { $set: MATERIALS_SUCCESS },
        },
      );
    }
    case MATERIALS_GET_LIST_FAILED: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: MATERIALS_FAILED },
        },
      );
    }
    case MATERIALS_FORM_SUBMIT_PROGRESS: {
      return update(
        state,
        { submitStatus: { $set: MATERIALS_PROGRESS } },
      );
    }
    case MATERIALS_FORM_SUBMIT_SUCCESS: {
      return update(
        state,
        {
          flash: { $set: action.payload },
          submitStatus: { $set: MATERIALS_SUCCESS },
        },
      );
    }
    case MATERIALS_FORM_SUBMIT_FAILED: {
      return update(
        state,
        { submitStatus: { $set: MATERIALS_FAILED } },
      );
    }
    case MATERIALS_GET_MATERIAL_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: MATERIALS_PROGRESS },
        },
      );
    }
    case MATERIALS_GET_MATERIAL_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.task },
          loadActiveStatus: { $set: MATERIALS_SUCCESS },
        },
      );
    }
    case MATERIALS_GET_MATERIAL_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: MATERIALS_FAILED },
        },
      );
    }
    case MATERIALS_PREVIEW_SUBMIT_PROGRESS: {
      return update(
        state, {
          previewStatus: { $set: MATERIALS_PROGRESS },
        },
      );
    }
    case MATERIALS_PREVIEW_SUBMIT_SUCCESS: {
      return update(
        state, {
          previewId: { $set: action.payload.id },
          previewStatus: { $set: MATERIALS_SUCCESS },
        },
      );
    }
    case MATERIALS_PREVIEW_SUBMIT_FAILED: {
      return update(
        state, {
          previewStatus: { $set: MATERIALS_FAILED },
        },
      );
    }
    case MATERIALS_CLEAR_STATE: {
      return update(
        state,
        {
          loadListStatus: { $set: '' },
          loadActiveStatus: { $set: '' },
          submitStatus: { $set: '' },
          previewStatus: { $set: '' },
          asyncStatus: { $set: '' },
          list: { $set: [] },
          pagination: { $set: {} },
          active: { $set: {} },
          asyncData: { $set: {} },
          previewId: { $set: '' },
        },
      );
    }
    case MATERIALS_CLEAR_FLASH_MESSAGE: {
      return update(
        state, {
          flash: { $set: '' },
        },
      );
    }
    default:
      return state;
  }
}
