import React, { PureComponent, Fragment } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Card, CardBody, Col, Button, ButtonToolbar, Row, Table,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  USERS_SUCCESS, USERS_FAILED,
  getStatistics as getStatisticsAction,
  userClearState as userClearStateAction,
} from '../../../redux/actions/usersAction';
import renderDatePickerField from '../../../shared/components/form/DatePicker';
import ButtonToNavigate from '../../../shared/components/form/ButtonToNavigate';
import { FullWideNotification } from '../../../shared/components/Notification';
import validate from '../validate';

class StatisticsForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    active: PropTypes.objectOf(PropTypes.any).isRequired,
    submitStatus: PropTypes.string.isRequired,
    loadStatus: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    userClearState: PropTypes.func.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    const { submitStatus, loadStatus, history } = nextProps;

    if (loadStatus === USERS_FAILED) {
      history.push('/404');
    }

    if (submitStatus === USERS_FAILED) {
      this.show('danger', 'Произошла непредвиденная ошибка. Обратитесь к разработчикам.');
    }
  }

  componentWillUnmount() {
    const { userClearState } = this.props;
    userClearState();
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  render() {
    const {
      handleSubmit, submitting, loadStatus, active,
    } = this.props;
    const {
      begin, end, createdUsersCount, ordersCount, courses,
    } = active;
    return (
      <Fragment>
        <Row>
          <form className="form w-100" onSubmit={handleSubmit}>
            <Col md={12} lg={6} xl={6}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Выберите даты</h5>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Начальная дата</span>
                    <div className="form__form-group-field">
                      <Field
                        name="begin"
                        component={renderDatePickerField}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Конечная дата</span>
                    <div className="form__form-group-field">
                      <Field
                        name="end"
                        component={renderDatePickerField}
                      />
                    </div>
                  </div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit" disabled={submitting}>Показать статистику</Button>
                    <Route
                      path="/"
                      render={props => <ButtonToNavigate {...props} title="Отмена" path="/users" />}
                    />
                  </ButtonToolbar>
                </CardBody>
              </Card>
            </Col>
            {loadStatus === USERS_SUCCESS && (
              <Col md={12} lg={6} xl={6}>
                <Card>
                  <CardBody>
                    <p className="text-uppercase"><strong>В период с {begin} по {end}, было:</strong></p>
                    <p>Создано новых аккаунтов: <strong>{createdUsersCount}</strong></p>
                    <p>Найдено новых заказов: <strong>{ordersCount}</strong></p>
                    <p>Открыт доступ к количеству курсов: <strong>{courses.length}</strong></p>
                  </CardBody>
                </Card>
              </Col>
            )}
          </form>
        </Row>
        {loadStatus === USERS_SUCCESS && (
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Какие курсы и какое количество были приобретены</h5>
                  </div>
                  <Table striped responsive>
                    <thead>
                      <tr>
                        <th width="10%">#</th>
                        <th width="50%">Название курса</th>
                        <th width="40%">Количество</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courses.map((course, index) => (
                        <tr key={course.id}>
                          <td>{index + 1}</td>
                          <td>{course.name}</td>
                          <td>{course.count}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  active: state.users.active,
  submitStatus: state.users.submitStatus,
  loadStatus: state.users.loadActiveStatus,
});

const mapDispatchToProps = dispatch => ({
  userClearState: () => dispatch(userClearStateAction()),
});

const EditFormComponent = reduxForm({
  form: 'users_statistics_form', // a unique identifier for this form
  validate,
  enableReinitialize: true,
  onSubmit: getStatisticsAction,
})(withTranslation('common')(withRouter(StatisticsForm)));

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditFormComponent);
