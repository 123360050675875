import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { updateUser as updateUserAction } from '../../../../redux/actions/usersAction';
import renderDropZoneField from '../../../../shared/components/form/DropZone';

class WizardFormSix extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit, previousPage, showNotification } = this.props;

    return (
      <form className="form form--horizontal wizard__form wizard__form__lg" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Загрузите фото пользователя</h3>
        <div className="form__form-group">
          <span className="form__form-group-label">Фото</span>
          <div className="form__form-group-field">
            <Field
              name="photo"
              component={renderDropZoneField}
              showNotification={showNotification}
              mimes={['image/jpeg', 'image/png']}
              maxFilesize={2097152} /* 2Mb */
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}>Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Редактировать пользователя</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'users_edit_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: false,
  onSubmit: updateUserAction,
})(WizardFormSix);
