import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  TAGS_SUCCESS, TAGS_FAILED,
  getTag as getTagAction,
  tagClearState as tagClearStateAction,
  updateTag as updateTagAction,
} from '../../../../redux/actions/tagsAction';
import ButtonToNavigate from '../../../../shared/components/form/ButtonToNavigate';
import renderField from '../../../../shared/components/form/TextField';
import { FullWideNotification } from '../../../../shared/components/Notification';
import validate from '../../validate';
import asyncValidate from '../../asyncValidate';

class EditForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    submitStatus: PropTypes.string.isRequired,
    loadStatus: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    getTag: PropTypes.func.isRequired,
    tagClearState: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { getTag, match } = this.props;
    const { tagId } = match.params;

    getTag(tagId);
  }

  componentWillReceiveProps(nextProps) {
    const { submitStatus, loadStatus, history } = nextProps;

    if (loadStatus === TAGS_FAILED) {
      history.push('/404');
    }

    if (submitStatus === TAGS_SUCCESS) {
      history.push('/tags');
    } else if (submitStatus === TAGS_FAILED) {
      this.show('danger', 'Произошла непредвиденная ошибка. Обратитесь к разработчикам.');
    }
  }

  componentWillUnmount() {
    const { tagClearState } = this.props;
    tagClearState();
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  render() {
    const {
      handleSubmit, submitting, t, loadStatus,
    } = this.props;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">ФОРМА РЕДАКТИРОВАНИЯ ТЕГА</h5>
              <h5 className="subhead">{t('maretials.forms.create_form_description')}</h5>
            </div>
            {loadStatus === TAGS_SUCCESS ? (
              <form className="form" onSubmit={handleSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Название тега</span>
                  <div className="form__form-group-field">
                    <Field
                      name="name"
                      component={renderField}
                      type="text"
                      placeholder="Название тега"
                    />
                  </div>
                </div>
                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit" disabled={submitting}>Редактировать</Button>
                  <Route
                    path="/"
                    render={props => <ButtonToNavigate {...props} title="Отмена" path="/tags" />}
                  />
                </ButtonToolbar>
              </form>
            ) : null}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: state.tags.active,
  submitStatus: state.tags.submitStatus,
  loadStatus: state.tags.loadActiveStatus,
});

const mapDispatchToProps = dispatch => ({
  getTag: tagId => dispatch(getTagAction(tagId)),
  tagClearState: () => dispatch(tagClearStateAction()),
});

const EditFormComponent = reduxForm({
  form: 'tags_edit_form', // a unique identifier for this form
  validate,
  asyncValidate,
  asyncBlurFields: ['name'],
  enableReinitialize: true,
  onSubmit: updateTagAction,
})(withTranslation('common')(withRouter(EditForm)));

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditFormComponent);
