import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import UserTablePage from '../../../Users/UserTable/index';
import UserCreatePage from '../../../Users/UserCreate/index';
import UserEditPage from '../../../Users/UserEdit/index';
import UserStatisticsPage from '../../../UserStatistics/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/users" exact component={UserTablePage} />
    <ProtectedRoute path="/users/create" component={UserCreatePage} />
    <ProtectedRoute path="/users/statistics" component={UserStatisticsPage} />
    <ProtectedRoute path="/users/:userId" exact component={UserEditPage} />
  </Switch>
);
