import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { FieldArray, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import RenderFlows from '../../components/RenderFlows';
import { updateHub as updateHubAction } from '../../../../redux/actions/hubsAction';
import validate from '../../validate';

class WizardFormTwo extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    asyncData: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  render() {
    const { handleSubmit, previousPage, asyncData } = this.props;
    const { flows } = asyncData;

    return (
      <form className="form form--horizontal wizard__form wizard__form__lg" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Выберите курсы, которые будут входить в данных хаб</h3>
        <FieldArray name="flows" component={RenderFlows} props={{ options: { flows } }} />
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}>Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Редактировать хаб</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'hubs_edit_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: false,
  onSubmit: updateHubAction,
  validate,
})(WizardFormTwo);
