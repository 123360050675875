import { startSubmit, stopSubmit } from 'redux-form';
import jwtDecode from 'jwt-decode';
import ws from '../../ws';
import {
  AUTH_SING_IN_PROGRESS,
  AUTH_SING_IN_SUCCESS,
  AUTH_SING_IN_FAILED,
  AUTH_TOKEN_RENEW_PROGRESS,
  AUTH_TOKEN_RENEW_SUCCESS,
  AUTH_TOKEN_RENEW_FAILED,
  AUTH_PROFILE_PROGRESS,
  AUTH_PROFILE_SUCCESS,
  AUTH_PROFILE_FAILED,
  AUTH_LOGOUT,
} from '../types/authTypes';

const authStartFormSubmit = () => ({
  type: AUTH_SING_IN_PROGRESS,
});

const authFormSubmitSuccess = payload => ({
  type: AUTH_SING_IN_SUCCESS,
  payload,
});

const authFormSubmitFailed = () => ({
  type: AUTH_SING_IN_FAILED,
});

const authLogOut = () => ({
  type: AUTH_LOGOUT,
});

const convertSetAuthToDB = data => ({
  email: data.email,
  password: data.password,
});

const getExpiresInDate = (payload) => {
  const { auth } = payload;
  const { tokens } = auth;

  const accessToken = jwtDecode(tokens.accessToken);
  const refreshToken = jwtDecode(tokens.refreshToken);
  return { ...payload, accessExp: accessToken.exp * 1000, refreshExp: refreshToken.exp * 1000 };
};

const singIn = async (data, dispatch, props) => {
  const { form, reset } = props;
  const convertedData = convertSetAuthToDB(data);

  dispatch(startSubmit(form));
  dispatch(authStartFormSubmit());

  ws.emit('api/academy/auth/singIn', { payload: convertedData, as: 'admin' }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      reset();
      dispatch(stopSubmit(form));
      dispatch(authFormSubmitSuccess(getExpiresInDate(payload)));
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(authFormSubmitFailed());
    }
  });
};

const getUserProfile = token => new Promise((resolve) => {
  ws.emit('api/academy/auth/getUserProfile', { token }, (result) => {
    resolve(result);
  });
});

const authStartProfileSubmit = () => ({
  type: AUTH_PROFILE_PROGRESS,
});

const authProfileSubmitSuccess = payload => ({
  type: AUTH_PROFILE_SUCCESS,
  payload,
});

const authProfileSubmitFailed = () => ({
  type: AUTH_PROFILE_FAILED,
});

const authStartTokenRenewSubmit = () => ({
  type: AUTH_TOKEN_RENEW_PROGRESS,
});

const authTokenRenewSubmitSuccess = payload => ({
  type: AUTH_TOKEN_RENEW_SUCCESS,
  payload,
});

const authTokenRenewSubmitFailed = () => ({
  type: AUTH_TOKEN_RENEW_FAILED,
});

export {
  authStartFormSubmit,
  authFormSubmitSuccess,
  authFormSubmitFailed,
  authLogOut,
  getExpiresInDate,
  singIn,
  getUserProfile,
  authStartProfileSubmit,
  authProfileSubmitSuccess,
  authProfileSubmitFailed,
  authStartTokenRenewSubmit,
  authTokenRenewSubmitSuccess,
  authTokenRenewSubmitFailed,
};
