import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import {
  Button, ButtonToolbar, Card, CardBody, Col, Row, Badge,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field, reduxForm, formValueSelector, reset,
} from 'redux-form';
import {
  MARAPHON_FLOWS_SUCCESS,
  getMaraphonFlowsList as getMaraphonFlowsListAction,
  maraphonFlowClearState as maraphonFlowClearStateAction,
  maraphonFlowClearFlashMessage as maraphonFlowClearFlashMessageAction,
} from '../../../../redux/actions/maraphonFlowsAction';
import renderField from '../../../../shared/components/form/TextField';
import renderSelectField from '../../../../shared/components/form/Select';
import DataTable from '../../../../shared/components/data-table';
import { COMPLETED, WAIT } from '../../../../shared/components/data-table/submitStatuses';
import { DATA, ACTION } from '../../../../shared/components/data-table/columnTypes';
import { LINK, OPEN_WINDOW } from '../../../../shared/components/data-table/buttonTypes';
import { FullWideNotification } from '../../../../shared/components/Notification';
import store from '../../../App/store';
import config from '../../../../config';

const MaraphonFlowTable = ({
  getMaraphonFlowsList,
  maraphonFlowClearState,
  maraphonFlowClearFlashMessage,
  showNotification,
  maraphonFlowsList,
  pagination,
  loadStatus,
  flashMessage,
  resetForm,
}) => {
  const [data, setData] = useState({
    columns: [],
    buttons: [],
    types: [],
    isFind: false,
    status: WAIT,
  });

  const clearFilters = () => {
    resetForm(MaraphonFlowTable.FORM_NAME);
    setData({ ...data, isFind: true });
  };

  const getConditions = () => {
    const selector = formValueSelector(MaraphonFlowTable.FORM_NAME);
    return selector(store.getState(), 'name', 'type');
  };

  const handleFind = () => {
    setData({ ...data, isFind: true });
  };

  const openLinkInNewWindow = (item) => {
    const { id } = item;
    return `${config.urls.school}maraphon_preview/${id}`;
  };

  const show = (color, message) => showNotification({
    notification: <FullWideNotification
      color={color}
      message={message}
    />,
    position: 'full',
  });

  useEffect(() => {
    if (loadStatus === MARAPHON_FLOWS_SUCCESS) {
      setData({ ...data, isFind: false });
    }
  }, [loadStatus]);

  useEffect(() => {
    if (flashMessage && flashMessage.length) {
      setTimeout(() => {
        show('success', flashMessage);
        maraphonFlowClearFlashMessage();
      }, 1000);
    }
  }, [flashMessage]);

  useEffect(() => {
    const types = [{
      value: 'flow',
      label: 'Поток',
    }, {
      value: 'instant',
      label: 'Моментальный',
    }];

    const columns = [{
      label: '#',
      dbColumn: 'createdAt',
      listColumn: 'number',
      sortable: true,
      width: 5,
      type: DATA,
    }, {
      label: 'Название потока',
      dbColumn: 'name',
      listColumn: 'name',
      sortable: true,
      width: 20,
      type: DATA,
    }, {
      label: 'Тип потока',
      dbColumn: 'maraphonType',
      listColumn: 'maraphonType',
      sortable: true,
      width: 15,
      handle: type => (type === 'flow'
        ? <Badge color="warning">Поток</Badge>
        : <Badge color="primary">Моментальный</Badge>),
      type: DATA,
    }, {
      label: 'Название марафона',
      dbColumn: 'maraphon.name',
      listColumn: 'title',
      sortable: false,
      width: 20,
      type: DATA,
    }, {
      label: 'Старт потока',
      dbColumn: 'date.begin',
      listColumn: 'begin',
      sortable: true,
      width: 10,
      type: DATA,
    }, {
      label: 'Окончание потока',
      dbColumn: 'date.end',
      listColumn: 'end',
      sortable: true,
      width: 10,
      type: DATA,
    }, {
      label: 'Создан',
      dbColumn: 'createdAt',
      listColumn: 'createdAt',
      sortable: true,
      width: 10,
      type: DATA,
    }, {
      label: 'Действия',
      dbColumn: '_id',
      listColumn: 'id',
      sortable: false,
      width: 10,
      type: ACTION,
    }];

    const buttons = [{
      name: 'edit',
      color: 'primary',
      icon: 'edit',
      type: LINK,
      link: '/maraphon_flows/',
      text: 'Редактировать',
    }, {
      name: 'link',
      color: 'primary',
      icon: 'link',
      type: OPEN_WINDOW,
      text: 'Открыть предпросмотр',
      handle: openLinkInNewWindow,
    }];

    setData(({
      ...data, columns, buttons, types, status: COMPLETED,
    }));

    return () => {
      maraphonFlowClearState();
    };
  }, []);

  return (
    <Fragment>
      {data.status === COMPLETED && (
        <Fragment>
          <Row>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Поиск по потокам марафонов</h5>
                  </div>
                  <form className="form gap-20">
                    <Col className="p-0">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Название потока</span>
                        <div className="form__form-group-field">
                          <Field
                            name="name"
                            component={renderField}
                            type="text"
                            placeholder="Название потока"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="p-0">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Тип потока</span>
                        <div className="form__form-group-field">
                          <Field
                            name="type"
                            component={renderSelectField}
                            placeholder="Тип потока"
                            options={data.types}
                          />
                        </div>
                      </div>
                    </Col>
                  </form>
                  <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                    <Button color="primary" type="button" onClick={handleFind}>Найти</Button>
                    <Button color="secondary" type="button" onClick={clearFilters}>Очистить</Button>
                  </ButtonToolbar>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <DataTable
                addButton={{ to: '/maraphon_flows/create', caption: 'Добавить поток' }}
                columns={data.columns}
                buttons={data.buttons}
                getConditions={getConditions}
                getList={getMaraphonFlowsList}
                list={maraphonFlowsList}
                pagination={pagination}
                isFind={data.isFind}
                loadListStatus={loadStatus}
                declensions={['поток', 'потока', 'потоков']}
              />
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

MaraphonFlowTable.FORM_NAME = 'maraphon_flows_table';

MaraphonFlowTable.propTypes = {
  getMaraphonFlowsList: PropTypes.func.isRequired,
  maraphonFlowClearState: PropTypes.func.isRequired,
  maraphonFlowClearFlashMessage: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  maraphonFlowsList: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.any),
  ).isRequired,
  // eslint-disable-next-line react/require-default-props
  pagination: PropTypes.objectOf(PropTypes.any),
  loadStatus: PropTypes.string.isRequired,
  flashMessage: PropTypes.string.isRequired,
  resetForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  maraphonFlowsList: state.maraphonFlows.list,
  pagination: state.maraphonFlows.pagination,
  loadStatus: state.maraphonFlows.loadListStatus,
  flashMessage: state.maraphonFlows.flash,
});

const mapDispatchToProps = dispatch => ({
  getMaraphonFlowsList: params => dispatch(getMaraphonFlowsListAction(params)),
  maraphonFlowClearState: () => dispatch(maraphonFlowClearStateAction()),
  maraphonFlowClearFlashMessage: () => dispatch(maraphonFlowClearFlashMessageAction()),
  resetForm: formName => dispatch(reset(formName)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(withRouter(MaraphonFlowTable)));

export default reduxForm({
  form: MaraphonFlowTable.FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(withConnect);
