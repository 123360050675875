import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TopbarMenuLink from './TopbarMenuLink';
import { authLogOut as authLogOutAction } from '../../../redux/actions/authAction';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

class TopbarProfile extends PureComponent {
  static propTypes = {
    profile: PropTypes.objectOf(PropTypes.any),
    authLogOut: PropTypes.func.isRequired,
  };

  static defaultProps = {
    profile: {},
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  logOutHandle = () => {
    const { authLogOut } = this.props;
    authLogOut();
  };

  render() {
    const { profile } = this.props;
    const { collapse } = this.state;

    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" type="button" onClick={this.toggle}>
          {profile && profile.photo ? (
            <img className="topbar__avatar-img" src={profile.photo} alt="avatar" />
          ) : (
            <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          )}
          {profile && (
            <p className="topbar__avatar-name">{profile.name}</p>
          )}
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink title="Выйти" icon="exit" path="/login" onClick={this.logOutHandle} />
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.auth.profile,
});

const mapDispatchToProps = dispatch => ({
  authLogOut: () => dispatch(authLogOutAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(withRouter(TopbarProfile)));
