import update from 'immutability-helper';
import {
  MARAPHON_FLOWS_PROGRESS,
  MARAPHON_FLOWS_SUCCESS,
  MARAPHON_FLOWS_FAILED,
  MARAPHON_FLOWS_GET_LIST_PROGRESS,
  MARAPHON_FLOWS_GET_LIST_SUCCESS,
  MARAPHON_FLOWS_GET_LIST_FAILED,
  MARAPHON_FLOWS_FORM_SUBMIT_PROGRESS,
  MARAPHON_FLOWS_FORM_SUBMIT_SUCCESS,
  MARAPHON_FLOWS_FORM_SUBMIT_FAILED,
  MARAPHON_FLOWS_GET_COURSE_PROGRESS,
  MARAPHON_FLOWS_GET_COURSE_SUCCESS,
  MARAPHON_FLOWS_GET_COURSE_FAILED,
  MARAPHON_FLOWS_GET_ASYNC_DATA_PROGRESS,
  MARAPHON_FLOWS_GET_ASYNC_DATA_SUCCESS,
  MARAPHON_FLOWS_GET_ASYNC_DATA_FAILED,
  MARAPHON_FLOWS_CLEAR_STATE,
  MARAPHON_FLOWS_CLEAR_FLASH_MESSAGE,
} from '../actions/maraphonFlowsAction';

const initialState = {
  loadListStatus: '', // progress/success/failed
  loadActiveStatus: '', // progress/success/failed
  submitStatus: '', // progress/success/failed
  asyncStatus: '', // progress/success/failed
  list: [],
  pagination: {},
  active: {},
  asyncData: {},
  flash: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MARAPHON_FLOWS_GET_LIST_PROGRESS: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: MARAPHON_FLOWS_PROGRESS },
        },
      );
    }
    case MARAPHON_FLOWS_GET_LIST_SUCCESS: {
      return update(
        state, {
          list: { $set: action.payload.list },
          pagination: { $set: action.payload.pagination },
          loadListStatus: { $set: MARAPHON_FLOWS_SUCCESS },
        },
      );
    }
    case MARAPHON_FLOWS_GET_LIST_FAILED: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: MARAPHON_FLOWS_FAILED },
        },
      );
    }
    case MARAPHON_FLOWS_FORM_SUBMIT_PROGRESS: {
      return update(
        state,
        { submitStatus: { $set: MARAPHON_FLOWS_PROGRESS } },
      );
    }
    case MARAPHON_FLOWS_FORM_SUBMIT_SUCCESS: {
      return update(
        state,
        {
          flash: { $set: action.payload },
          submitStatus: { $set: MARAPHON_FLOWS_SUCCESS },
        },
      );
    }
    case MARAPHON_FLOWS_FORM_SUBMIT_FAILED: {
      return update(
        state,
        { submitStatus: { $set: MARAPHON_FLOWS_FAILED } },
      );
    }
    case MARAPHON_FLOWS_GET_COURSE_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: MARAPHON_FLOWS_PROGRESS },
        },
      );
    }
    case MARAPHON_FLOWS_GET_COURSE_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.flow },
          loadActiveStatus: { $set: MARAPHON_FLOWS_SUCCESS },
        },
      );
    }
    case MARAPHON_FLOWS_GET_COURSE_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: MARAPHON_FLOWS_FAILED },
        },
      );
    }
    case MARAPHON_FLOWS_GET_ASYNC_DATA_PROGRESS: {
      return update(
        state, {
          asyncData: { $set: {} },
          asyncStatus: { $set: MARAPHON_FLOWS_PROGRESS },
        },
      );
    }
    case MARAPHON_FLOWS_GET_ASYNC_DATA_SUCCESS: {
      return update(
        state, {
          asyncData: { $set: action.payload },
          asyncStatus: { $set: MARAPHON_FLOWS_SUCCESS },
        },
      );
    }
    case MARAPHON_FLOWS_GET_ASYNC_DATA_FAILED: {
      return update(
        state, {
          asyncData: { $set: {} },
          asyncStatus: { $set: MARAPHON_FLOWS_FAILED },
        },
      );
    }
    case MARAPHON_FLOWS_CLEAR_STATE: {
      return update(
        state,
        {
          loadListStatus: { $set: '' },
          loadActiveStatus: { $set: '' },
          submitStatus: { $set: '' },
          asyncStatus: { $set: '' },
          list: { $set: [] },
          pagination: { $set: {} },
          active: { $set: {} },
          asyncData: { $set: {} },
        },
      );
    }
    case MARAPHON_FLOWS_CLEAR_FLASH_MESSAGE: {
      return update(
        state, {
          flash: { $set: '' },
        },
      );
    }
    default:
      return state;
  }
}
