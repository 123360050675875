class PaginationPageConverter {
  constructor(data, docsConvertFn) {
    const {
      docs, hasNextPage, hasPrevPage, limit, nextPage, page, pagingCounter, prevPage, totalDocs, totalPages,
    } = data;

    this.fn = docsConvertFn;
    this.docsStartNumber = page * limit - limit;
    this.docs = docs;
    this.pagination = {
      docsNumber: docs.length,
      hasNextPage,
      hasPrevPage,
      limit,
      nextPage,
      page,
      pagingCounter,
      prevPage,
      totalDocs,
      totalPages,
    };
  }

  getPagination() {
    return this.pagination;
  }

  getDocs() {
    let number = this.docsStartNumber;
    return this.docs.map((doc) => {
      number += 1;

      return {
        number,
        ...this.fn(doc),
      };
    });
  }

  getConvertedData() {
    return {
      pagination: this.getPagination(),
      list: this.getDocs(),
    };
  }
}

export default PaginationPageConverter;
