import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, FieldArray } from 'redux-form';
import ArrowUpBold from 'mdi-react/ArrowUpBoldIcon';
import ArrowDownBoldIcon from 'mdi-react/ArrowDownBoldIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import renderSelectField from '../../../shared/components/form/Select';
import RenderThemes from './RenderThemes';

class RenderCourses extends PureComponent {
  static propTypes = {
    fields: PropTypes.objectOf(PropTypes.any).isRequired,
    meta: PropTypes.objectOf(PropTypes.any).isRequired,
    courses: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  };

  static defaultProps = {
    courses: null,
  };

  static moveToUp(fields, index) {
    if (index > 0) {
      fields.swap(index, index - 1);
    }
  }

  static moveToDown(fields, index) {
    if (fields.length - 1 !== index) {
      fields.swap(index, index + 1);
    }
  }

  state = {
    countCourses: 0,
    disableAddButton: false,
  };

  componentDidMount() {
    const { courses, fields } = this.props;

    const allFields = fields.getAll();
    const academyCount = !allFields ? 0 : allFields.length;
    const disableAddButton = academyCount >= courses.length;

    this.setState({ countCourses: courses.length, disableAddButton });
  }

  getCourses = (index) => {
    const { courses, fields } = this.props;
    const academy = fields.getAll();
    const names = [];
    const selectedCourse = fields.get(index);

    const isSelected = (courseId) => {
      let isCourseSelected = false;

      for (let i = 0; i < academy.length; i += 1) {
        if (typeof academy[i].course !== 'undefined') {
          const { course } = academy[i];

          if (course.value === courseId) {
            isCourseSelected = true;
            break;
          }
        }
      }

      return isCourseSelected;
    };

    if (academy && courses) {
      for (let i = 0; i < courses.length; i += 1) {
        if (!isSelected(courses[i].course.value)) {
          names.push(courses[i].course);
        }
      }
    }

    return typeof selectedCourse.course !== 'undefined' ? [selectedCourse.course] : names;
  };

  addCourse = () => {
    const { fields } = this.props;
    const { countCourses } = this.state;

    fields.push({});
    const allFields = fields.getAll();
    const academyCount = !allFields ? 1 : allFields.length + 1;

    if (academyCount >= countCourses) {
      this.setState({ disableAddButton: true });
    }
  };

  removeCourse = (index) => {
    const { fields } = this.props;
    const { countCourses } = this.state;

    fields.remove(index);
    const allFields = fields.getAll();
    const academyCount = !allFields ? 1 : allFields.length - 1;

    if (academyCount < countCourses) {
      this.setState({ disableAddButton: false });
    }
  };

  getThemes = (index) => {
    const { courses, fields } = this.props;
    const select = fields.get(index);
    let themeOptions = [];

    if (typeof select.course !== 'undefined' && courses) {
      const { value: courseId } = select.course;

      for (let i = 0; i < courses.length; i += 1) {
        if (courses[i].course.value === courseId) {
          themeOptions = courses[i].themes;
          break;
        }
      }
    }

    return themeOptions;
  };

  render() {
    const { fields, meta: { error, submitFailed } } = this.props;
    const { moveToUp, moveToDown } = RenderCourses;
    const { disableAddButton } = this.state;

    return (
      <div className="form__form-group">
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button
            color="primary"
            size="sm"
            disabled={disableAddButton}
            onClick={this.addCourse}
          >
            Добавить курс
          </Button>
        </ButtonToolbar>
        {submitFailed && error
        && (
          <div className="form__form-group">
            <div>
              <span className="form__form-group-error">{error}</span>
            </div>
          </div>
        )}
        {fields.map((course, index) => (
          <div key={index} className="form__form-group">
            <span className="form__form-group-label">Курс # {index + 1}</span>
            <div className="form__form-group-field">
              <Field
                name={`${course}.course`}
                component={renderSelectField}
                type="text"
                placeholder="Выберите курс"
                options={this.getCourses(index)}
              />
              <div className="form__form-group-icon">
                <ArrowUpBold onClick={() => moveToUp(fields, index)} />
              </div>
              <div className="form__form-group-icon">
                <ArrowDownBoldIcon onClick={() => moveToDown(fields, index)} />
              </div>
              <div className="form__form-group-icon">
                <TrashCanOutlineIcon onClick={() => this.removeCourse(index)} />
              </div>
            </div>
            <div className="form__form-group-field">
              <FieldArray
                name={`${course}.themes`}
                component={RenderThemes}
                props={{ options: this.getThemes(index) }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default RenderCourses;
