import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import BannerTablePage from '../../../Banners/BannerTable/index';
import BannerEditPage from '../../../Banners/BannerEdit/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/banners" exact component={BannerTablePage} />
    <ProtectedRoute path="/banners/:bannerId" exact component={BannerEditPage} />
  </Switch>
);
