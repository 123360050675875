import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import {
  Button, ButtonToolbar, Card, CardBody, Col, Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field, reduxForm, formValueSelector, reset,
} from 'redux-form';
import {
  DUPLICATES_SUCCESS,
  getDuplicatesList as getDuplicatesListAction,
  duplicateClearState as duplicateClearStateAction,
  duplicateClearFlashMessage as duplicateClearFlashMessageAction,
} from '../../../../redux/actions/duplicatesAction';
import renderField from '../../../../shared/components/form/TextField';
import DataTable from '../../../../shared/components/data-table';
import { COMPLETED, WAIT } from '../../../../shared/components/data-table/submitStatuses';
import { DATA, ACTION } from '../../../../shared/components/data-table/columnTypes';
import { LINK } from '../../../../shared/components/data-table/buttonTypes';
import { FullWideNotification } from '../../../../shared/components/Notification';
import store from '../../../App/store';

const DuplicatesTable = ({
  getDuplicatesList,
  duplicateClearState,
  duplicateClearFlashMessage,
  showNotification,
  duplicatesList,
  pagination,
  loadStatus,
  flashMessage,
  resetForm,
}) => {
  const [data, setData] = useState({
    columns: [],
    buttons: [],
    isFind: false,
    status: WAIT,
  });

  const clearFilters = () => {
    resetForm(DuplicatesTable.FORM_NAME);
    setData({ ...data, isFind: true });
  };

  const getConditions = () => {
    const selector = formValueSelector(DuplicatesTable.FORM_NAME);
    const email = selector(store.getState(), 'email');

    return { email };
  };

  const handleFind = () => {
    setData({ ...data, isFind: true });
  };

  const show = (color, message) => showNotification({
    notification: <FullWideNotification
      color={color}
      message={message}
    />,
    position: 'full',
  });

  useEffect(() => {
    if (loadStatus === DUPLICATES_SUCCESS) {
      setData({ ...data, isFind: false });
    }
  }, [loadStatus]);

  useEffect(() => {
    if (flashMessage && flashMessage.length) {
      setTimeout(() => {
        show('success', flashMessage);
        duplicateClearFlashMessage();
      }, 1000);
    }
  }, [flashMessage]);

  useEffect(() => {
    const columns = [{
      label: '#',
      dbColumn: 'createdAt',
      listColumn: 'number',
      sortable: true,
      width: 5,
      type: DATA,
    }, {
      label: 'Email',
      dbColumn: 'local.email',
      listColumn: 'email',
      sortable: true,
      width: 40,
      type: DATA,
    }, {
      label: 'ID в базе',
      dbColumn: '_id',
      listColumn: 'baseId',
      sortable: false,
      width: 20,
      type: DATA,
    }, {
      label: 'Создан',
      dbColumn: 'createdAt',
      listColumn: 'createdAt',
      sortable: true,
      width: 20,
      type: DATA,
    }, {
      label: 'Действия',
      dbColumn: '_id',
      listColumn: 'id',
      sortable: false,
      width: 15,
      type: ACTION,
    }];

    const buttons = [{
      name: 'edit',
      color: 'primary',
      icon: 'edit',
      type: LINK,
      link: '/duplicates/',
      text: 'Редактировать',
    }];

    setData(({
      ...data, columns, buttons, status: COMPLETED,
    }));

    return () => {
      duplicateClearState();
    };
  }, []);

  return (
    <Fragment>
      {data.status === COMPLETED && (
        <Fragment>
          <Row>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Поиск по дубликатам</h5>
                  </div>
                  <form className="form gap-20">
                    <Col className="p-0">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Email</span>
                        <div className="form__form-group-field">
                          <Field
                            name="email"
                            component={renderField}
                            type="text"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                    </Col>
                  </form>
                  <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                    <Button color="primary" type="button" onClick={handleFind}>Найти</Button>
                    <Button color="secondary" type="button" onClick={clearFilters}>Очистить</Button>
                  </ButtonToolbar>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <DataTable
                columns={data.columns}
                buttons={data.buttons}
                getConditions={getConditions}
                getList={getDuplicatesList}
                list={duplicatesList}
                pagination={pagination}
                isFind={data.isFind}
                loadListStatus={loadStatus}
                declensions={['дубликат', 'дубликата', 'дубликатов']}
              />
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

DuplicatesTable.FORM_NAME = 'duplicates_table';

DuplicatesTable.propTypes = {
  getDuplicatesList: PropTypes.func.isRequired,
  duplicateClearState: PropTypes.func.isRequired,
  duplicateClearFlashMessage: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  duplicatesList: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.any),
  ).isRequired,
  // eslint-disable-next-line react/require-default-props
  pagination: PropTypes.objectOf(PropTypes.any),
  loadStatus: PropTypes.string.isRequired,
  flashMessage: PropTypes.string.isRequired,
  resetForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  duplicatesList: state.duplicates.list,
  pagination: state.duplicates.pagination,
  loadStatus: state.duplicates.loadListStatus,
  flashMessage: state.duplicates.flash,
});

const mapDispatchToProps = dispatch => ({
  getDuplicatesList: params => dispatch(getDuplicatesListAction(params)),
  duplicateClearState: () => dispatch(duplicateClearStateAction()),
  duplicateClearFlashMessage: () => dispatch(duplicateClearFlashMessageAction()),
  resetForm: formName => dispatch(reset(formName)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(withRouter(DuplicatesTable)));

export default reduxForm({
  form: DuplicatesTable.FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(withConnect);
