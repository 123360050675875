import {
  getUserProfile, authStartProfileSubmit, authProfileSubmitSuccess, authProfileSubmitFailed,
} from '../../redux/actions/authAction';
import { AUTH_PROGRESS, AUTH_PROFILE_SUCCESS } from '../../redux/types/authTypes';

export default function requestProfileMiddleware() {
  // eslint-disable-next-line
  return ({ getState }) => next => (action) => {
    const { profile, profileStatus } = getState().auth;

    if (profile || profileStatus === AUTH_PROGRESS || profileStatus === AUTH_PROFILE_SUCCESS) {
      return next(action);
    }

    const { tokens, exp } = getState().auth;

    if (!tokens || Date.now() > exp) {
      return next(action);
    }

    next(authStartProfileSubmit());

    getUserProfile(tokens.accessToken)
      .then((result) => {
        const { status, payload } = result;

        if (status === 'ok') {
          next(authProfileSubmitSuccess(payload));
        } else {
          next(authProfileSubmitFailed());
        }
      });
  };
}
