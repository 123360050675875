import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Button, ButtonToolbar, Card, CardBody, Col, Row,
} from 'reactstrap';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  SHOWCASES_SUCCESS, SHOWCASES_FAILED,
  createShowcase as createShowcaseAction,
  getAsyncData as getAsyncDataAction,
  showcaseClearState as showcaseClearStateAction,
} from '../../../../redux/actions/showcasesAction';
import ButtonToNavigate from '../../../../shared/components/form/ButtonToNavigate';
import renderField from '../../../../shared/components/form/TextField';
import renderTextarea from '../../../../shared/components/form/TextareaField';
import renderSelectField from '../../../../shared/components/form/Select';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import renderSimpleCodeField from '../../../../shared/components/form/SimpleCodeField';
import RenderFaq from '../../components/RenderFaq';
import { FullWideNotification } from '../../../../shared/components/Notification';
import validate from '../../validate';

class CreateForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    asyncData: PropTypes.objectOf(PropTypes.any).isRequired,
    asyncStatus: PropTypes.string.isRequired,
    submitStatus: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    getAsyncData: PropTypes.func.isRequired,
    showcaseClearState: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      showDiscount: false,
      showPreview: false,
    };
  }

  componentDidMount() {
    const { getAsyncData } = this.props;
    getAsyncData();
  }

  componentWillReceiveProps(nextProps) {
    const { submitStatus, history } = nextProps;

    if (submitStatus === SHOWCASES_SUCCESS) {
      history.push('/showcases');
    } else if (submitStatus === SHOWCASES_FAILED) {
      this.show('danger', 'Произошла непредвиденная ошибка. Обратитесь к разработчикам.');
    }
  }

  componentWillUnmount() {
    const { showcaseClearState } = this.props;
    showcaseClearState();
  }

  getCurrentState() {
    return { ...this.state };
  }

  handleShow = (event, newValue) => {
    const showPreview = typeof newValue === 'boolean' ? newValue : false;
    const currentState = this.getCurrentState();
    this.setState({ ...currentState, showPreview });
  };

  handleDiscount = (event, newValue) => {
    const showDiscount = typeof newValue === 'boolean' ? newValue : false;
    const currentState = this.getCurrentState();
    this.setState({ ...currentState, showDiscount });
  };

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  render() {
    const {
      handleSubmit, asyncStatus, asyncData, submitting,
    } = this.props;
    const { flows } = asyncData;
    const { showDiscount, showPreview } = this.state;

    return (
      <Col md={12} lg={12}>
        {asyncStatus === SHOWCASES_SUCCESS && (
          <form className="form" onSubmit={handleSubmit}>
            <Card>
              <CardBody>
                <div className="card__title mt-3 mb-3">
                  <h5 className="bold-text">Курс, который будет отображаться на витрине</h5>
                </div>
                <Row>
                  <Col md={8}>
                    <div className="form__form-group form__form-select">
                      <span className="form__form-group-label">Поток</span>
                      <div className="form__form-group-field">
                        <Field
                          name="maraphonFlow"
                          component={renderSelectField}
                          placeholder="Выберите поток"
                          options={flows}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Сортировка</span>
                      <div className="form__form-group-field">
                        <Field
                          name="sort"
                          component={renderField}
                          type="number"
                          placeholder="Сортировка"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <div className="form__form-group mb-3">
                      <div className="form__form-group-field">
                        <Field
                          name="isShow"
                          component={renderCheckBoxField}
                          label="Отображать на витрине"
                          onChange={this.handleShow}
                        />
                      </div>
                    </div>
                  </Col>
                  {showPreview && (
                    <Col md={9}>
                      <div className="form__form-group mb-3">
                        <div className="form__form-group-field">
                          <Field
                            name="isPreview"
                            component={renderCheckBoxField}
                            label="Видимый только для администратора"
                          />
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
                <div className="form__form-group">
                  <span className="form__form-group-label">Альтернативное название</span>
                  <div className="form__form-group-field">
                    <Field
                      name="alternativeName"
                      component={renderField}
                      type="text"
                      placeholder="Альтернативное название"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Альтернативное краткое описание</span>
                  <div className="form__form-group-field">
                    <Field
                      name="alternativeDescription"
                      component={renderTextarea}
                      placeholder="Альтернативное краткое описание"
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="card__title mt-3 mb-3">
                  <h5 className="bold-text">Цена и скидка</h5>
                </div>
                <Row>
                  <Col md={4}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Цена в грн.</span>
                      <div className="form__form-group-field">
                        <Field
                          name="price"
                          component={renderField}
                          type="number"
                          placeholder="Цена"
                          maxlength={7}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Идентификатор продуктовой формы</span>
                      <div className="form__form-group-field">
                        <Field
                          name="productId"
                          component={renderField}
                          type="text"
                          placeholder="Идентификатор продуктовой формы"
                          maxlength={24}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">&nbsp;</span>
                      <div className="form__form-group-field">
                        <Field
                          name="discountIsEnable"
                          component={renderCheckBoxField}
                          label="Применить скидку"
                          onChange={this.handleDiscount}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                {showDiscount && (
                  <Row>
                    <Col md={4}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Цена со скидкой в грн.</span>
                        <div className="form__form-group-field">
                          <Field
                            name="discountPrice"
                            component={renderField}
                            type="number"
                            placeholder="Цена со скидкой"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Процент скидки</span>
                        <div className="form__form-group-field">
                          <Field
                            name="discountPercent"
                            component={renderField}
                            type="number"
                            placeholder="Процент скидки"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Скидка доступна до</span>
                        <div className="form__form-group-field">
                          <Field
                            name="discountDate"
                            component={renderDatePickerField}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="card__title mt-3 mb-3">
                  <h5 className="bold-text">Описание</h5>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Описание сверху курса (произвольный html)</span>
                  <div className="form__form-group-field">
                    <Field
                      name="descriptionTop"
                      component={renderSimpleCodeField}
                      placeholder="Описание сверху курса"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Описание снизу курса (произвольный html)</span>
                  <div className="form__form-group-field">
                    <Field
                      name="descriptionBottom"
                      component={renderSimpleCodeField}
                      placeholder="Описание снизу курса"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Описание в сплывающем окне (произвольный html)</span>
                  <div className="form__form-group-field">
                    <Field
                      name="descriptionModal"
                      component={renderSimpleCodeField}
                      placeholder="Описание в сплывающем окне"
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="card__title mt-3 mb-3">
                  <h5 className="bold-text">Вопросы и ответы</h5>
                </div>
                <FieldArray name="faq" component={RenderFaq} />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit" disabled={submitting}>Создать</Button>
                  <Route
                    path="/"
                    render={props => <ButtonToNavigate {...props} title="Отмена" path="/showcases" />}
                  />
                </ButtonToolbar>
              </CardBody>
            </Card>
          </form>
        )}
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  asyncData: state.showcases.asyncData,
  asyncStatus: state.showcases.asyncStatus,
  submitStatus: state.showcases.submitStatus,
});

const mapDispatchToProps = dispatch => ({
  getAsyncData: () => dispatch(getAsyncDataAction()),
  showcaseClearState: () => dispatch(showcaseClearStateAction()),
});

const CreateFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateForm);

export default reduxForm({
  form: 'showcases_create_form', // a unique identifier for this form
  validate,
  onSubmit: createShowcaseAction,
})(withTranslation('common')(withRouter(CreateFormComponent)));
