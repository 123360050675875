import moment from 'moment';
import { startSubmit, stopSubmit } from 'redux-form';
import ws from '../../ws';
import getToken from './getToken';
import PaginationPageConverter from '../../shared/classes/PaginationPageConverter';

const FAQS_PROGRESS = 'progress';
const FAQS_SUCCESS = 'success';
const FAQS_FAILED = 'failed';

const FAQS_GET_LIST_PROGRESS = 'FAQS_GET_LIST_PROGRESS';
const FAQS_GET_LIST_SUCCESS = 'FAQS_GET_LIST_SUCCESS';
const FAQS_GET_LIST_FAILED = 'FAQS_GET_LIST_FAILED';
const FAQS_FORM_SUBMIT_PROGRESS = 'FAQS_FORM_SUBMIT_PROGRESS';
const FAQS_FORM_SUBMIT_SUCCESS = 'FAQS_FORM_SUBMIT_SUCCESS';
const FAQS_FORM_SUBMIT_FAILED = 'FAQS_FORM_SUBMIT_FAILED';
const FAQS_GET_PAGE_PROGRESS = 'FAQS_GET_PAGE_PROGRESS';
const FAQS_GET_PAGE_SUCCESS = 'FAQS_GET_PAGE_SUCCESS';
const FAQS_GET_PAGE_FAILED = 'FAQS_GET_PAGE_FAILED';
const FAQS_CLEAR_STATE = 'FAQS_CLEAR_STATE';

const FAQS_CLEAR_FLASH_MESSAGE = 'FAQS_CLEAR_FLASH_MESSAGE';

const faqGetListProgress = () => ({
  type: FAQS_GET_LIST_PROGRESS,
});

const faqGetListSuccess = payload => ({
  type: FAQS_GET_LIST_SUCCESS,
  payload,
});

const faqGetListFailed = () => ({
  type: FAQS_GET_LIST_FAILED,
});

const faqStartFormSubmit = () => ({
  type: FAQS_FORM_SUBMIT_PROGRESS,
});

const faqFormSubmitSuccess = payload => ({
  type: FAQS_FORM_SUBMIT_SUCCESS,
  payload,
});

const faqFormSubmitFailed = () => ({
  type: FAQS_FORM_SUBMIT_FAILED,
});

const faqGetPageProgress = () => ({
  type: FAQS_GET_PAGE_PROGRESS,
});

const faqGetPageSuccess = payload => ({
  type: FAQS_GET_PAGE_SUCCESS,
  payload,
});

const faqGetPageFailed = () => ({
  type: FAQS_GET_PAGE_FAILED,
});

const faqClearState = () => ({
  type: FAQS_CLEAR_STATE,
});

const convertListToTable = data => new PaginationPageConverter(data, doc => ({
  id: doc.id,
  question: doc.question,
  createdAt: moment(doc.createdAt).locale('ru').format('DD MMM YY г., HH:mm'),
})).getConvertedData();

const getFaqsList = params => (dispatch) => {
  const token = getToken();
  dispatch(faqGetListProgress());

  ws.emit('api/academy/faqs/list', { token, payload: params }, (data) => {
    const { status, payload } = data;
    let action;

    if (status === 'ok') {
      action = faqGetListSuccess(convertListToTable(payload.data));
    } else {
      action = faqGetListFailed();
    }

    dispatch(action);
  });
};

const convertSetFaqToDB = (data) => {
  const {
    question, answer, question_ua: questionUa, answer_ua: answerUa,
  } = data;

  return {
    question,
    answer,
    question_ua: questionUa,
    answer_ua: answerUa,
  };
};

const createFaq = (data, dispatch, props) => {
  const token = getToken();
  const { form, reset } = props;
  const convertedData = convertSetFaqToDB(data);

  dispatch(startSubmit(form));
  dispatch(faqStartFormSubmit());

  ws.emit('api/academy/faqs/create', {
    token, payload: { convertedData },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      reset();
      dispatch(stopSubmit(form));
      dispatch(faqFormSubmitSuccess('Новый вопрос/ответ был успешно создан.'));
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(faqFormSubmitFailed());
    }
  });
};

const convertDBFaqToEdit = (payload) => {
  const { faq: faqFromDB } = payload;
  const faq = {
    id: faqFromDB.id,
    question: faqFromDB.question,
    answer: faqFromDB.answer,
    question_ua: faqFromDB.question_ua,
    answer_ua: faqFromDB.answer_ua,
  };

  return { faq };
};

const getFaq = faqId => async (dispatch) => {
  const token = getToken();
  dispatch(faqGetPageProgress());

  ws.emit('api/academy/faqs/getById', {
    token, payload: { faqId },
  }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = faqGetPageSuccess(convertDBFaqToEdit(payload));
    } else {
      action = faqGetPageFailed();
    }

    dispatch(action);
  });
};

const updateFaq = (data, dispatch, props) => {
  const token = getToken();
  const { id } = data;
  const { form } = props;
  const convertedData = convertSetFaqToDB(data);

  dispatch(startSubmit(form));
  dispatch(faqStartFormSubmit());

  ws.emit('api/academy/faqs/updateById', {
    token, payload: { faqId: id, convertedData },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(stopSubmit(form));
      dispatch(faqFormSubmitSuccess('Вопрос/ответ был успешно отредактирован.'));
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(faqFormSubmitFailed());
    }
  });
};

const faqClearFlashMessage = () => ({
  type: FAQS_CLEAR_FLASH_MESSAGE,
});

export {
  FAQS_PROGRESS,
  FAQS_SUCCESS,
  FAQS_FAILED,
  FAQS_GET_LIST_PROGRESS,
  FAQS_GET_LIST_SUCCESS,
  FAQS_GET_LIST_FAILED,
  FAQS_FORM_SUBMIT_PROGRESS,
  FAQS_FORM_SUBMIT_SUCCESS,
  FAQS_FORM_SUBMIT_FAILED,
  FAQS_GET_PAGE_PROGRESS,
  FAQS_GET_PAGE_SUCCESS,
  FAQS_GET_PAGE_FAILED,
  FAQS_CLEAR_STATE,
  FAQS_CLEAR_FLASH_MESSAGE,
  faqGetListProgress,
  faqGetListSuccess,
  faqGetListFailed,
  faqStartFormSubmit,
  faqFormSubmitSuccess,
  faqFormSubmitFailed,
  faqGetPageProgress,
  faqGetPageSuccess,
  faqGetPageFailed,
  faqClearState,
  getFaqsList,
  createFaq,
  getFaq,
  updateFaq,
  faqClearFlashMessage,
};
