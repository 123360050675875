import React, {
  useEffect, useRef, useState, forwardRef, Fragment,
} from 'react';
import PropTypes from 'prop-types';
import Hls from 'hls.js';
import { usePlyr } from 'plyr-react';
import 'plyr-react/dist/plyr.css';
import poster from './poster.webp';

const defaultOptions = {
  controls: [
    'play-large',
    'rewind',
    'play',
    'fast-forward',
    'progress',
    'current-time',
    'duration',
    'mute',
    'volume',
    'captions',
    'settings',
    'pip',
    'fullscreen',
  ],
  speed: {
    selected: 1,
    options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
  },
  seekTime: 10,
  i18n: {
    quality: 'Качество',
    speed: 'Скорость',
  },
  ratio: '16:9',
};

const defaultQuality = [
  {
    resolution: [1080, 1920],
    caption: 1080,
  },
  {
    resolution: [720, 1280],
    caption: 720,
  },
  {
    resolution: [540, 960],
    caption: 540,
  },
  {
    resolution: [360, 640],
    caption: 360,
  },
];

// const get720QualityIndex = (levels) => {
//   const quality720Index = defaultQuality.findIndex(quality => quality.caption === 720);
//   const quality720 = defaultQuality.find(quality => quality.caption === 720);
//   const { resolution: quality720Resolution } = quality720;
//
//   const index = levels.findIndex(level => quality720Resolution.indexOf(level.height) !== -1);
//   return index !== -1 ? index : levels[levels.length - 1].height;
// };

const getUniquePlyrId = () => {
  const maxIndexes = [9, 10, 11, 12];
  const randomIndex = maxIndexes[Math.floor(Math.random() * maxIndexes.length)];
  const uniqueStr = Math.random().toString(36);

  return `plyr-instance-${uniqueStr.substring(2, randomIndex)}`;
};

const useHls = (src, options, plyrId) => {
  const thirtyMegabytes = 31457280;
  const hls = useRef(new Hls({ autoStartLoad: false, maxBufferSize: thirtyMegabytes }));
  const hasQuality = useRef(false);
  const startLoad = useRef(false);
  const [plyrOptions, setPlyrOptions] = useState({
    ...defaultOptions,
    ...options,
    listeners: {
      play() {
        if (!startLoad.current) {
          hls.current.startLoad(-1);
          startLoad.current = true;
        }
      },
    },
  });

  useEffect(() => () => {
    hls.current.destroy();
  }, []);

  useEffect(() => {
    hasQuality.current = false;
  }, [options]);

  useEffect(() => {
    hls.current.loadSource(src);
    hls.current.attachMedia(document.getElementById(plyrId));
    hls.current.on(Hls.Events.MANIFEST_PARSED, () => {
      if (hasQuality.current) return; // early quit if already set

      const { levels } = hls.current;
      const quality = {
        // default: get720QualityIndex(levels),
        default: 720,
        options: defaultQuality.map(q => q.caption),
        forced: true,
        onChange: (newQuality) => {
          levels.forEach((level, levelIndex) => {
            if (level.height === newQuality || level.width === newQuality) {
              hls.current.currentLevel = levelIndex;
            }
          });
        },
      };
      setPlyrOptions({ ...plyrOptions, quality });
      hasQuality.current = true;
    });
  });

  return { options: plyrOptions };
};

const PlyrInstance = forwardRef(({ hlsSource, options, source }, ref) => {
  const [plyrId] = useState(getUniquePlyrId());
  const raptorRef = usePlyr(ref, { ...useHls(hlsSource, options, plyrId), source });
  const supported = Hls.isSupported();

  return (
    <Fragment>
      {supported ? (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video ref={raptorRef} className="plyr-react plyr" id={plyrId} crossOrigin="anonymous" data-poster={poster} />
      ) : (
        'HLS is not supported in your browser'
      )}
    </Fragment>
  );
});

PlyrInstance.defaultProps = {
  source: null,
  options: null,
};

PlyrInstance.propTypes = {
  hlsSource: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]).isRequired,
  source: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  options: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.oneOf([null]),
  ]),
};

export default PlyrInstance;
