import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderField from '../../../../shared/components/form/TextField';
import validate from '../../validate';
import asyncValidate from '../../asyncValidate';

class WizardFormOne extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Заполните общею информацию о хабе</h3>
        <div className="form__form-group">
          <span className="form__form-group-label">Название хаба</span>
          <div className="form__form-group-field">
            <Field
              name="name"
              component={renderField}
              type="text"
              placeholder="Название хаба"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">AMO ID</span>
          <div className="form__form-group-field">
            <Field
              name="amoId"
              component={renderField}
              type="text"
              placeholder="AMO ID"
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" disabled className="previous">Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Следующий шаг</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'hubs_create_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  asyncValidate,
  asyncBlurFields: ['amoId'],
})(WizardFormOne);
