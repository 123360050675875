import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Profile from '../../../Account/Profile/index';
import EmailConfirmation from '../../../Account/EmailConfimation/index';
import LogInPhoto from '../../../Account/log_in_photo/index';
import LogIn from '../../../Account/LogIn/index';

export default () => (
  <Switch>
    <Route path="/account/profile" component={Profile} />
    <Route path="/account/email_confirmation" component={EmailConfirmation} />
    <Route path="/account/log_in_photo" component={LogInPhoto} />
    <Route path="/account/login" component={LogIn} />
  </Switch>
);
