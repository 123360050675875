import {
  combineReducers,
  createStore,
  applyMiddleware,
  compose,
} from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import thunk from 'redux-thunk';
import requestProfileMiddleware from './requestProfileMiddleware';
import {
  authReducer,
  bannersReducer,
  coursesReducer,
  duplicatesReducer,
  flowsReducer,
  imagesReducer,
  hubsReducer,
  groupsReducer,
  tagsReducer,
  themesReducer,
  materialsReducer,
  maraphonTaskReducer,
  maraphonsReducer,
  maraphonFlowReducer,
  pagesReducer,
  prolongationReducer,
  questionsReducer,
  usersReducer,
  cryptoTableReducer,
  newOrderTableReducer,
  sidebarReducer,
  themeReducer,
  customizerReducer,
  todoReducer,
  webinarsReducer,
  faqsReducer,
  showcasesReducer,
} from '../../redux/reducers/index';

const reducer = combineReducers({
  form: reduxFormReducer,
  auth: authReducer,
  banners: bannersReducer,
  courses: coursesReducer,
  duplicates: duplicatesReducer,
  flows: flowsReducer,
  images: imagesReducer,
  hubs: hubsReducer,
  groups: groupsReducer,
  tags: tagsReducer,
  themes: themesReducer,
  materials: materialsReducer,
  maraphonTasks: maraphonTaskReducer,
  maraphons: maraphonsReducer,
  maraphonFlows: maraphonFlowReducer,
  pages: pagesReducer,
  prolongation: prolongationReducer,
  questions: questionsReducer,
  users: usersReducer,
  theme: themeReducer,
  sidebar: sidebarReducer,
  cryptoTable: cryptoTableReducer,
  newOrder: newOrderTableReducer,
  customizer: customizerReducer,
  todos: todoReducer,
  webinars: webinarsReducer,
  faqs: faqsReducer,
  showcases: showcasesReducer,
});
const initialState = {};
/* eslint-disable no-underscore-dangle */
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// eslint-disable-next-line
const setAuthState = (state) => {
  try {
    localStorage.setItem('tokens', JSON.stringify((state.auth || {}).tokens));
  } catch (err) { return undefined; }
};
const store = createStore(
  reducer,
  initialState,
  storeEnhancers(applyMiddleware(thunk, requestProfileMiddleware())),
);

store.subscribe(() => {
  setAuthState(store.getState());
});

export default store;
