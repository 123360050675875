const validate = (values) => {
  // eslint-disable-next-line
  const validUrl = /(http(s)?:\/\/.)(www\.)?[-a-za-я0-9@:%._\+~#=]{2,256}\.[a-zа-я]{2,6}\b([-a-zа-я0-9@:%_\+.~#?&//=]*)/i;
  const errors = {};
  const requiredErrorMessage = 'Это поле является обязательным';

  if (values.useLink) {
    if (!values.link) {
      errors.link = requiredErrorMessage;
    } else if (values.link && !values.link.match(validUrl)) {
      errors.link = 'Вы ввели неверный URL адрес';
    }
  }

  return errors;
};

export default validate;
