import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../Layout/index';
import Banners from './Banners';
import Commerce from './Commerce';
import Crypto from './Crypto';
import Courses from './Courses';
import Documentation from './Documentation';
import Duplicates from './Duplicates';
import DefaultPages from './DefaultPages';
import Flows from './Flows';
import Images from './Images';
import Hubs from './Hubs';
import Groups from './Groups';
import Account from './Account';
import ECommerce from './ECommerce';
import Maps from './Maps';
import Charts from './Charts';
import Tables from './Tables';
import Tags from './Tags';
import Themes from './Themes';
import Materials from './Materials';
import MaraphonTasks from './MaraphonTasks';
import Maraphons from './Maraphons';
import MaraphonFlows from './MaraphonFlows';
import Pages from './Pages';
import Prolongation from './Prolongation';
import Questions from './Questions';
import Forms from './Forms';
import UI from './UI';
import Users from './Users';
import Webinars from './Webinars';
import Faqs from './Faqs';
import Showcases from './Showcases';

import Chat from '../../../Chat/index';
import Todo from '../../../Todo/index';

import FitnessDashboard from '../../../Dashboards/Fitness/index';
import DefaultDashboard from '../../../Dashboards/Default/index';
import MobileAppDashboard from '../../../Dashboards/MobileApp/index';
import BookingDashboard from '../../../Dashboards/Booking/index';

import Mail from '../../../Mail/index';

export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/dashboard_default" component={DefaultDashboard} />
      <Route path="/dashboard_e_commerce" component={Commerce} />
      <Route path="/dashboard_fitness" component={FitnessDashboard} />
      <Route path="/dashboard_crypto" component={Crypto} />
      <Route exact path="/dashboard_mobile_app" component={MobileAppDashboard} />
      <Route path="/dashboard_booking" component={BookingDashboard} />
      <Route path="/duplicates" component={Duplicates} />
      <Route path="/banners" component={Banners} />
      <Route path="/flows" component={Flows} />
      <Route path="/images" component={Images} />
      <Route path="/hubs" component={Hubs} />
      <Route path="/groups" component={Groups} />
      <Route path="/courses" component={Courses} />
      <Route path="/ui" component={UI} />
      <Route path="/mail" component={Mail} />
      <Route path="/chat" component={Chat} />
      <Route path="/todo" component={Todo} />
      <Route path="/materials" component={Materials} />
      <Route path="/maraphon_tasks" component={MaraphonTasks} />
      <Route path="/maraphons" component={Maraphons} />
      <Route path="/maraphon_flows" component={MaraphonFlows} />
      <Route path="/pages" component={Pages} />
      <Route path="/prolongation" component={Prolongation} />
      <Route path="/questions" component={Questions} />
      <Route path="/users" component={Users} />
      <Route path="/forms" component={Forms} />
      <Route path="/tables" component={Tables} />
      <Route path="/tags" component={Tags} />
      <Route path="/themes" component={Themes} />
      <Route path="/charts" component={Charts} />
      <Route path="/maps" component={Maps} />
      <Route path="/account" component={Account} />
      <Route path="/e-commerce" component={ECommerce} />
      <Route path="/default_pages" component={DefaultPages} />
      <Route path="/documentation" component={Documentation} />
      <Route path="/webinars" component={Webinars} />
      <Route path="/faqs" component={Faqs} />
      <Route path="/showcases" component={Showcases} />
    </div>
  </div>
);
