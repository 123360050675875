import update from 'immutability-helper';
import {
  THEMES_PROGRESS,
  THEMES_SUCCESS,
  THEMES_FAILED,
  THEMES_GET_LIST_PROGRESS,
  THEMES_GET_LIST_SUCCESS,
  THEMES_GET_LIST_FAILED,
  THEMES_FORM_SUBMIT_PROGRESS,
  THEMES_FORM_SUBMIT_SUCCESS,
  THEMES_FORM_SUBMIT_FAILED,
  THEMES_GET_COURSE_PROGRESS,
  THEMES_GET_COURSE_SUCCESS,
  THEMES_GET_COURSE_FAILED,
  THEMES_GET_ASYNC_DATA_PROGRESS,
  THEMES_GET_ASYNC_DATA_SUCCESS,
  THEMES_GET_ASYNC_DATA_FAILED,
  THEMES_CLEAR_STATE,
  THEMES_CLEAR_FLASH_MESSAGE,
} from '../actions/themesAction';

const initialState = {
  loadListStatus: '', // progress/success/failed
  loadActiveStatus: '', // progress/success/failed
  submitStatus: '', // progress/success/failed
  asyncStatus: '', // progress/success/failed
  list: [],
  pagination: {},
  active: {},
  asyncData: {},
  flash: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case THEMES_GET_LIST_PROGRESS: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: THEMES_PROGRESS },
        },
      );
    }
    case THEMES_GET_LIST_SUCCESS: {
      return update(
        state, {
          list: { $set: action.payload.list },
          pagination: { $set: action.payload.pagination },
          loadListStatus: { $set: THEMES_SUCCESS },
        },
      );
    }
    case THEMES_GET_LIST_FAILED: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: THEMES_FAILED },
        },
      );
    }
    case THEMES_FORM_SUBMIT_PROGRESS: {
      return update(
        state,
        { submitStatus: { $set: THEMES_PROGRESS } },
      );
    }
    case THEMES_FORM_SUBMIT_SUCCESS: {
      return update(
        state,
        {
          flash: { $set: action.payload },
          submitStatus: { $set: THEMES_SUCCESS },
        },
      );
    }
    case THEMES_FORM_SUBMIT_FAILED: {
      return update(
        state,
        { submitStatus: { $set: THEMES_FAILED } },
      );
    }
    case THEMES_GET_COURSE_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: THEMES_PROGRESS },
        },
      );
    }
    case THEMES_GET_COURSE_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.theme },
          loadActiveStatus: { $set: THEMES_SUCCESS },
        },
      );
    }
    case THEMES_GET_COURSE_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: THEMES_FAILED },
        },
      );
    }
    case THEMES_GET_ASYNC_DATA_PROGRESS: {
      return update(
        state, {
          asyncData: { $set: {} },
          asyncStatus: { $set: THEMES_PROGRESS },
        },
      );
    }
    case THEMES_GET_ASYNC_DATA_SUCCESS: {
      return update(
        state, {
          asyncData: { $set: action.payload },
          asyncStatus: { $set: THEMES_SUCCESS },
        },
      );
    }
    case THEMES_GET_ASYNC_DATA_FAILED: {
      return update(
        state, {
          asyncData: { $set: {} },
          asyncStatus: { $set: THEMES_FAILED },
        },
      );
    }
    case THEMES_CLEAR_STATE: {
      return update(
        state,
        {
          loadListStatus: { $set: '' },
          loadActiveStatus: { $set: '' },
          submitStatus: { $set: '' },
          asyncStatus: { $set: '' },
          list: { $set: [] },
          pagination: { $set: {} },
          active: { $set: {} },
          asyncData: { $set: {} },
        },
      );
    }
    case THEMES_CLEAR_FLASH_MESSAGE: {
      return update(
        state, {
          flash: { $set: '' },
        },
      );
    }
    default:
      return state;
  }
}
