import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import WebinarTablePage from '../../../Webinars/WebinarTable/index';
import WebinarCreatePage from '../../../Webinars/WebinarCreate/index';
import WebinarEditPage from '../../../Webinars/WebinarEdit/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/webinars" exact component={WebinarTablePage} />
    <ProtectedRoute path="/webinars/create" component={WebinarCreatePage} />
    <ProtectedRoute path="/webinars/:webinarId" exact component={WebinarEditPage} />
  </Switch>
);
