import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  TAGS_SUCCESS, TAGS_FAILED,
  createTag as createTagAction, tagClearState as tagClearStateAction,
} from '../../../redux/actions/tagsAction';
import renderField from '../../../shared/components/form/TextField';
import { FullWideNotification } from '../../../shared/components/Notification';
import validate from '../../Tags/validate';
import asyncValidate from '../../Tags/asyncValidate';

const rightPosition = {
  width: '100%',
  justifyContent: 'flex-end',
};

const successTagAdded = {
  fontSize: '10px',
  lineHeight: '13px',
  color: '#1d9f29',
  marginBottom: '-5px',
  display: 'block',
  marginTop: '5px',
};

class CreateTag extends PureComponent {
  static propTypes = {
    // t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitStatus: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    tagClearState: PropTypes.func.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    const { submitStatus } = nextProps;

    if (submitStatus === TAGS_FAILED) {
      this.show('danger', 'Произошла непредвиденная ошибка. Обратитесь к разработчикам.');
    }
  }

  componentWillUnmount() {
    const { tagClearState } = this.props;
    tagClearState();
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  focusHandle = () => {
    const { tagClearState } = this.props;
    tagClearState();
  };

  render() {
    const {
      handleSubmit, submitting, submitStatus, // t,
    } = this.props;

    return (
      <div>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">Добавить новый тег в выпадающий список</span>
            <div className="form__form-group-field">
              <Field
                name="name"
                component={renderField}
                type="text"
                placeholder="Название тега"
                onFocus={this.focusHandle}
              />
            </div>
            {submitStatus === TAGS_SUCCESS ? <span style={successTagAdded}>Новый тег был добавлен</span> : null}
          </div>
          <ButtonToolbar className="form__button-toolbar" style={rightPosition}>
            <Button color="success" size="sm" type="submit" disabled={submitting}>Добавить новый тег</Button>
          </ButtonToolbar>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  submitStatus: state.tags.submitStatus,
});

const mapDispatchToProps = dispatch => ({
  tagClearState: () => dispatch(tagClearStateAction()),
});

const CreateTagComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateTag);

export default reduxForm({
  form: 'questions_tags_create_form', // a unique identifier for this form
  validate,
  asyncValidate,
  asyncBlurFields: ['name'],
  onSubmit: createTagAction,
})(withTranslation('common')(withRouter(CreateTagComponent)));
