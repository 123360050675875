import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism.css';

const renderSimpleCode = ({
  input, placeholder, meta: { touched, error }, options: { initValue },
}) => {
  const [code, setCode] = useState('');

  useEffect(() => {
    setCode(initValue);
  }, []);

  return (
    <div className="form__form-group-input-wrap">
      <div className="simple-code__scroll">
        <Editor
          value={code}
          onChange={input.onChange}
          onValueChange={value => setCode(value)}
          highlight={value => highlight(value, languages.markup, 'markup')}
          padding={10}
          style={{
            fontFamily: 'monospace',
            fontSize: 12,
          }}
          className="simple-code__editor"
          preClassName="language-markup"
          placeholder={placeholder}
        />
      </div>
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
  );
};

renderSimpleCode.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.shape({
    initValue: PropTypes.string,
  }),
};

renderSimpleCode.defaultProps = {
  placeholder: '',
  meta: null,
  options: {
    initValue: '',
  },
};

export default renderSimpleCode;
