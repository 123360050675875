import io from 'socket.io-client';
import config from './config';
import store from './containers/App/store';
import {
  authStartTokenRenewSubmit,
  authTokenRenewSubmitSuccess,
  authTokenRenewSubmitFailed,
  getExpiresInDate,
} from './redux/actions/authAction';

const apiUrl = config.urls.api;
const socket = io(apiUrl);
const { emit } = socket;

// ===================================

let isNowRefreshToken = false;
let deferredArguments = [];

// eslint-disable-next-line
socket.emit = function (event) {
  const now = Date.now();
  const { auth } = store.getState();
  const { accessExp, refreshExp, tokens } = auth;

  const refreshToken = () => new Promise((resolve, reject) => {
    store.dispatch(authStartTokenRenewSubmit());

    const args = [
      'api/academy/auth/renewToken', // Имя события для обновления пары токенов
      { token: tokens.refreshToken }, // Объект с refreshToken
      (result) => { // Функция обработчик новых токенов, которые вернул API
        const { status, payload } = result;
        // console.log(result);
        // Если API вернул новую пару токенов
        if (status === 'ok') {
          // Сохраняем их в Redux и localStorage
          // store.dispatch(authTokenRenewSubmitSuccess(getExpiresInDate(payload)));
          resolve(payload);
        } else {
          // store.dispatch(authTokenRenewSubmitFailed());
          reject();
        }
      },
    ];

    emit.apply(socket, args);
  });

  // eslint-disable-next-line
  if (arguments[1].hasOwnProperty('token') && now > (accessExp - 60000)) {
    if (now < refreshExp) {
      // eslint-disable-next-line
      deferredArguments.push(arguments);

      if (!isNowRefreshToken) {
        isNowRefreshToken = true;

        refreshToken()
          .then((payload) => {
            // eslint-disable-next-line
            console.log('update token');
            return store.dispatch(authTokenRenewSubmitSuccess(getExpiresInDate(payload)));
          })
          .then(() => {
            const { auth: updatedAuth } = store.getState();
            const { tokens: updatedTokens } = updatedAuth;
            const { accessToken } = updatedTokens;

            // eslint-disable-next-line
            for (const deferredArgument of deferredArguments) {
              deferredArgument[1].token = accessToken;
              emit.apply(socket, deferredArgument);
            }
          })
          .catch(() => {
            // eslint-disable-next-line
            console.log('cancel');
            store.dispatch(authTokenRenewSubmitFailed());
          })
          .finally(() => {
            isNowRefreshToken = false;
            deferredArguments = [];
          });
      }
    } else {
      store.dispatch(authTokenRenewSubmitFailed());
    }
  } else {
    // eslint-disable-next-line
    emit.apply(socket, arguments);
  }
};

export default socket;
