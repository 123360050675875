import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { createMaraphon as createMaraphonAction } from '../../../../redux/actions/maraphonsAction';
import renderSketchColorPickerField from '../../../../shared/components/form/SketchColorPicker';

class WizardFormFour extends PureComponent {
  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit, previousPage, submitting } = this.props;

    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Стилизуйте цветовую схему марафона</h3>
        <div className="form__form-group">
          <span className="form__form-group-label">Цвет фона</span>
          <div className="form__form-group-field">
            <Field
              name="backgroundColor"
              component={renderSketchColorPickerField}
              hex="3877d1"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Цвет названия марафона</span>
          <div className="form__form-group-field">
            <Field
              name="courseNameColor"
              component={renderSketchColorPickerField}
              hex="ffffff"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Цвет автора марафона</span>
          <div className="form__form-group-field">
            <Field
              name="courseAuthorColor"
              component={renderSketchColorPickerField}
              hex="fd890a"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Цвет прогресс бара</span>
          <div className="form__form-group-field">
            <Field
              name="courseProgressColor"
              component={renderSketchColorPickerField}
              hex="296dce"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Цвет фона кнопки</span>
          <div className="form__form-group-field">
            <Field
              name="buttonBackgroundColor"
              component={renderSketchColorPickerField}
              hex="2a6ecf"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Цвет текста на кнопке</span>
          <div className="form__form-group-field">
            <Field
              name="buttonTextColor"
              component={renderSketchColorPickerField}
              hex="ffffff"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Цвет рамок кнопки</span>
          <div className="form__form-group-field">
            <Field
              name="buttonBorderColor"
              component={renderSketchColorPickerField}
              hex="ffffff"
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}>Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next" disabled={submitting}>Создать новый марафон</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'maraphons_create_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: createMaraphonAction,
})(WizardFormFour);
