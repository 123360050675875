import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderField from '../../../../shared/components/form/TextField';
import renderTextarea from '../../../../shared/components/form/TextareaField';
import renderSelectField from '../../../../shared/components/form/Select';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import validate from '../../validate';
import asyncValidate from '../../asyncValidate';

class WizardFormOne extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line
    initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    asyncData: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  render() {
    const { handleSubmit, asyncData } = this.props;
    const { directions } = asyncData;

    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Заполните общею информацию о курсе</h3>
        <div className="form__form-group">
          <span className="form__form-group-label">Название курса</span>
          <div className="form__form-group-field">
            <Field
              name="name"
              component={renderField}
              type="text"
              placeholder="Название курса"
            />
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="isVisible"
              component={renderCheckBoxField}
              label="Отображать этот курс в общем списке курсов"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">URL</span>
          <div className="form__form-group-field">
            <Field
              name="slug"
              component={renderField}
              type="text"
              placeholder="URL"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Авторы курса</span>
          <div className="form__form-group-field">
            <Field
              name="author"
              component={renderField}
              type="text"
              placeholder="Авторы курса"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Краткое описание</span>
          <div className="form__form-group-field">
            <Field
              name="description"
              component={renderTextarea}
              placeholder="Краткое описание"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Ссылка на лендинг</span>
          <div className="form__form-group-field">
            <Field
              name="landing"
              component={renderField}
              type="text"
              placeholder="Ссылка на лендинг"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Направление</span>
          <div className="form__form-group-field">
            <Field
              name="direction"
              component={renderSelectField}
              placeholder="Выберите направление"
              options={directions}
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" disabled className="previous">Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Следующий шаг</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'courses_edit_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: false,
  validate,
  asyncValidate,
  asyncBlurFields: ['slug'],
})(WizardFormOne);
