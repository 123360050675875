import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import {
  Button, ButtonToolbar, Card, CardBody, Col, Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field, reduxForm, formValueSelector, reset,
} from 'redux-form';
import {
  PAGES_SUCCESS,
  getPagesList as getPagesListAction,
  pageClearState as pageClearStateAction,
  pageClearFlashMessage as pageClearFlashMessageAction,
} from '../../../../redux/actions/pagesAction';
import renderField from '../../../../shared/components/form/TextField';
import DataTable from '../../../../shared/components/data-table';
import { COMPLETED, WAIT } from '../../../../shared/components/data-table/submitStatuses';
import { DATA, ACTION } from '../../../../shared/components/data-table/columnTypes';
import { LINK } from '../../../../shared/components/data-table/buttonTypes';
import { FullWideNotification } from '../../../../shared/components/Notification';
import store from '../../../App/store';

const PageTable = ({
  getPagesList,
  pageClearState,
  pageClearFlashMessage,
  showNotification,
  pagesList,
  pagination,
  loadStatus,
  flashMessage,
  resetForm,
}) => {
  const [data, setData] = useState({
    columns: [],
    buttons: [],
    isFind: false,
    status: WAIT,
  });

  const clearFilters = () => {
    resetForm(PageTable.FORM_NAME);
    setData({ ...data, isFind: true });
  };

  const getConditions = () => {
    const selector = formValueSelector(PageTable.FORM_NAME);
    const name = selector(store.getState(), 'name');

    return { name };
  };

  const handleFind = () => {
    setData({ ...data, isFind: true });
  };

  const show = (color, message) => showNotification({
    notification: <FullWideNotification
      color={color}
      message={message}
    />,
    position: 'full',
  });

  useEffect(() => {
    if (loadStatus === PAGES_SUCCESS) {
      setData({ ...data, isFind: false });
    }
  }, [loadStatus]);

  useEffect(() => {
    if (flashMessage && flashMessage.length) {
      setTimeout(() => {
        show('success', flashMessage);
        pageClearFlashMessage();
      }, 1000);
    }
  }, [flashMessage]);

  useEffect(() => {
    const columns = [{
      label: '#',
      dbColumn: 'createdAt',
      listColumn: 'number',
      sortable: true,
      width: 5,
      type: DATA,
    }, {
      label: 'Название страницы',
      dbColumn: 'name',
      listColumn: 'name',
      sortable: true,
      width: 65,
      type: DATA,
    }, {
      label: 'Создан',
      dbColumn: 'createdAt',
      listColumn: 'createdAt',
      sortable: true,
      width: 20,
      type: DATA,
    }, {
      label: 'Действия',
      dbColumn: '_id',
      listColumn: 'id',
      sortable: false,
      width: 10,
      type: ACTION,
    }];

    const buttons = [{
      name: 'edit',
      color: 'primary',
      icon: 'edit',
      type: LINK,
      link: '/pages/',
      text: 'Редактировать',
    }];

    setData(({
      ...data, columns, buttons, status: COMPLETED,
    }));

    return () => {
      pageClearState();
    };
  }, []);

  return (
    <Fragment>
      {data.status === COMPLETED && (
        <Fragment>
          <Row>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Поиск по страницам</h5>
                  </div>
                  <form className="form gap-20">
                    <Col className="p-0">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Название страницы</span>
                        <div className="form__form-group-field">
                          <Field
                            name="name"
                            component={renderField}
                            type="text"
                            placeholder="Название страницы"
                          />
                        </div>
                      </div>
                    </Col>
                  </form>
                  <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                    <Button color="primary" type="button" onClick={handleFind}>Найти</Button>
                    <Button color="secondary" type="button" onClick={clearFilters}>Очистить</Button>
                  </ButtonToolbar>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <DataTable
                addButton={{ to: '/pages/create', caption: 'Добавить страницу' }}
                columns={data.columns}
                buttons={data.buttons}
                getConditions={getConditions}
                getList={getPagesList}
                list={pagesList}
                pagination={pagination}
                isFind={data.isFind}
                loadListStatus={loadStatus}
                declensions={['страница', 'страницы', 'страниц']}
              />
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

PageTable.FORM_NAME = 'pages_table';

PageTable.propTypes = {
  getPagesList: PropTypes.func.isRequired,
  pageClearState: PropTypes.func.isRequired,
  pageClearFlashMessage: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  pagesList: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.any),
  ).isRequired,
  // eslint-disable-next-line react/require-default-props
  pagination: PropTypes.objectOf(PropTypes.any),
  loadStatus: PropTypes.string.isRequired,
  flashMessage: PropTypes.string.isRequired,
  resetForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  pagesList: state.pages.list,
  pagination: state.pages.pagination,
  loadStatus: state.pages.loadListStatus,
  flashMessage: state.pages.flash,
});

const mapDispatchToProps = dispatch => ({
  getPagesList: params => dispatch(getPagesListAction(params)),
  pageClearState: () => dispatch(pageClearStateAction()),
  pageClearFlashMessage: () => dispatch(pageClearFlashMessageAction()),
  resetForm: formName => dispatch(reset(formName)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(withRouter(PageTable)));

export default reduxForm({
  form: PageTable.FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(withConnect);
