const validate = (values) => {
  const errors = {};
  const requiredErrorMessage = 'Это поле является обязательным';
  const dateErrorMessage = 'Вы ввели неверную дату';

  if (!values.begin) {
    errors.begin = requiredErrorMessage;
  } else if (!(values.begin instanceof Date)) {
    errors.begin = dateErrorMessage;
  }

  if (!values.end) {
    errors.end = requiredErrorMessage;
  } else if (!(values.end instanceof Date)) {
    errors.end = dateErrorMessage;
  }

  if (values.begin instanceof Date && values.end instanceof Date) {
    if (values.end.getTime() < values.begin.getTime()) {
      errors.end = 'Конечная дата должны быть больше начальной даты';
    }
  }

  return errors;
};

export default validate;
