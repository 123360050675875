import store from '../../containers/App/store';

const getToken = () => {
  let token = null;
  const { tokens } = store.getState().auth;

  if (tokens) {
    token = tokens.accessToken;
  }

  return token;
};

export default getToken;
