import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import {
  Button, ButtonToolbar, Card, CardBody, Col, Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field, reduxForm, formValueSelector, reset,
} from 'redux-form';
import {
  HUBS_SUCCESS,
  getHubsList as getHubsListAction,
  hubClearState as hubClearStateAction,
  hubClearFlashMessage as hubClearFlashMessageAction,
} from '../../../../redux/actions/hubsAction';
import renderField from '../../../../shared/components/form/TextField';
import DataTable from '../../../../shared/components/data-table';
import { COMPLETED, WAIT } from '../../../../shared/components/data-table/submitStatuses';
import { DATA, ACTION } from '../../../../shared/components/data-table/columnTypes';
import { LINK } from '../../../../shared/components/data-table/buttonTypes';
import { FullWideNotification } from '../../../../shared/components/Notification';
import store from '../../../App/store';

const HubTable = ({
  getHubsList,
  hubClearState,
  hubClearFlashMessage,
  showNotification,
  hubsList,
  pagination,
  loadStatus,
  flashMessage,
  resetForm,
}) => {
  const [data, setData] = useState({
    columns: [],
    buttons: [],
    isFind: false,
    status: WAIT,
  });

  const clearFilters = () => {
    resetForm(HubTable.FORM_NAME);
    setData({ ...data, isFind: true });
  };

  const getConditions = () => {
    const selector = formValueSelector(HubTable.FORM_NAME);
    return selector(store.getState(), 'name', 'amoId');
  };

  const handleFind = () => {
    setData({ ...data, isFind: true });
  };

  const show = (color, message) => showNotification({
    notification: <FullWideNotification
      color={color}
      message={message}
    />,
    position: 'full',
  });

  useEffect(() => {
    if (loadStatus === HUBS_SUCCESS) {
      setData({ ...data, isFind: false });
    }
  }, [loadStatus]);

  useEffect(() => {
    if (flashMessage && flashMessage.length) {
      setTimeout(() => {
        show('success', flashMessage);
        hubClearFlashMessage();
      }, 1000);
    }
  }, [flashMessage]);

  useEffect(() => {
    const columns = [{
      label: '#',
      dbColumn: 'createdAt',
      listColumn: 'number',
      sortable: true,
      width: 5,
      type: DATA,
    }, {
      label: 'Название хаба',
      dbColumn: 'name',
      listColumn: 'name',
      sortable: true,
      width: 50,
      type: DATA,
    }, {
      label: 'Pipedrive ID',
      dbColumn: 'amoId',
      listColumn: 'amoId',
      sortable: true,
      width: 15,
      type: DATA,
    }, {
      label: 'Создан',
      dbColumn: 'createdAt',
      listColumn: 'createdAt',
      sortable: true,
      width: 20,
      type: DATA,
    }, {
      label: 'Действия',
      dbColumn: '_id',
      listColumn: 'id',
      sortable: false,
      width: 10,
      type: ACTION,
    }];

    const buttons = [{
      name: 'edit',
      color: 'primary',
      icon: 'edit',
      type: LINK,
      link: '/hubs/',
      text: 'Редактировать',
    }];

    setData(({
      ...data, columns, buttons, status: COMPLETED,
    }));

    return () => {
      hubClearState();
    };
  }, []);

  return (
    <Fragment>
      {data.status === COMPLETED && (
        <Fragment>
          <Row>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Поиск по хабам</h5>
                  </div>
                  <form className="form gap-20">
                    <Col className="p-0">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Название хаба</span>
                        <div className="form__form-group-field">
                          <Field
                            name="name"
                            component={renderField}
                            type="text"
                            placeholder="Название хаба"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="p-0">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Pipedrive ID</span>
                        <div className="form__form-group-field">
                          <Field
                            name="amoId"
                            component={renderField}
                            type="text"
                            placeholder="Pipedrive ID"
                          />
                        </div>
                      </div>
                    </Col>
                  </form>
                  <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                    <Button color="primary" type="button" onClick={handleFind}>Найти</Button>
                    <Button color="secondary" type="button" onClick={clearFilters}>Очистить</Button>
                  </ButtonToolbar>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <DataTable
                addButton={{ to: '/hubs/create', caption: 'Добавить хаб' }}
                columns={data.columns}
                buttons={data.buttons}
                getConditions={getConditions}
                getList={getHubsList}
                list={hubsList}
                pagination={pagination}
                isFind={data.isFind}
                loadListStatus={loadStatus}
                declensions={['хаб', 'хаба', 'хабов']}
              />
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

HubTable.FORM_NAME = 'hubs_table';

HubTable.propTypes = {
  getHubsList: PropTypes.func.isRequired,
  hubClearState: PropTypes.func.isRequired,
  hubClearFlashMessage: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  hubsList: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.any),
  ).isRequired,
  // eslint-disable-next-line react/require-default-props
  pagination: PropTypes.objectOf(PropTypes.any),
  loadStatus: PropTypes.string.isRequired,
  flashMessage: PropTypes.string.isRequired,
  resetForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  hubsList: state.hubs.list,
  pagination: state.hubs.pagination,
  loadStatus: state.hubs.loadListStatus,
  flashMessage: state.hubs.flash,
});

const mapDispatchToProps = dispatch => ({
  getHubsList: params => dispatch(getHubsListAction(params)),
  hubClearState: () => dispatch(hubClearStateAction()),
  hubClearFlashMessage: () => dispatch(hubClearFlashMessageAction()),
  resetForm: formName => dispatch(reset(formName)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(withRouter(HubTable)));

export default reduxForm({
  form: HubTable.FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(withConnect);
