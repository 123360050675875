import ws from '../../ws';
import getToken from '../../redux/actions/getToken';

class NameError extends Error {
  name = 'Такой тег уже существует';
}

const asyncValidate = async (values) => {
  const token = getToken();
  const data = { name: values.name.replace(/\s{2,}/g, ' '), token };
  data.id = Object.prototype.hasOwnProperty.call(values, 'id') ? values.id : null;

  return new Promise((resolve, reject) => {
    ws.emit('api/academy/tags/checkAsyncData', data, (result) => {
      const { status, payload } = result;

      if (status === 'ok' && payload) {
        reject(new NameError());
      } else {
        resolve();
      }
    });
  });
};

export default asyncValidate;
