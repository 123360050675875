import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import {
  Button, ButtonToolbar, Card, CardBody, Col, Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field, reduxForm, formValueSelector, reset,
} from 'redux-form';
import {
  FAQS_SUCCESS,
  getFaqsList as getFaqsListAction,
  faqClearState as faqClearStateAction,
  faqClearFlashMessage as faqClearFlashMessageAction,
} from '../../../../redux/actions/faqsAction';
import renderField from '../../../../shared/components/form/TextField';
import DataTable from '../../../../shared/components/data-table';
import { COMPLETED, WAIT } from '../../../../shared/components/data-table/submitStatuses';
import { DATA, ACTION } from '../../../../shared/components/data-table/columnTypes';
import { LINK } from '../../../../shared/components/data-table/buttonTypes';
import { FullWideNotification } from '../../../../shared/components/Notification';
import store from '../../../App/store';

const FaqTable = ({
  getFaqsList,
  faqClearState,
  faqClearFlashMessage,
  showNotification,
  faqsList,
  pagination,
  loadStatus,
  flashMessage,
  resetForm,
}) => {
  const [data, setData] = useState({
    columns: [],
    buttons: [],
    isFind: false,
    status: WAIT,
  });

  const clearFilters = () => {
    resetForm(FaqTable.FORM_NAME);
    setData({ ...data, isFind: true });
  };

  const getConditions = () => {
    const selector = formValueSelector(FaqTable.FORM_NAME);
    const question = selector(store.getState(), 'question');

    return { question };
  };

  const handleFind = () => {
    setData({ ...data, isFind: true });
  };

  const show = (color, message) => showNotification({
    notification: <FullWideNotification
      color={color}
      message={message}
    />,
    position: 'full',
  });

  useEffect(() => {
    if (loadStatus === FAQS_SUCCESS) {
      setData({ ...data, isFind: false });
    }
  }, [loadStatus]);

  useEffect(() => {
    if (flashMessage && flashMessage.length) {
      setTimeout(() => {
        show('success', flashMessage);
        faqClearFlashMessage();
      }, 1000);
    }
  }, [flashMessage]);

  useEffect(() => {
    const columns = [{
      label: '#',
      dbColumn: 'createdAt',
      listColumn: 'number',
      sortable: true,
      width: 5,
      type: DATA,
    }, {
      label: 'Вопрос',
      dbColumn: 'question',
      listColumn: 'question',
      sortable: true,
      width: 65,
      type: DATA,
    }, {
      label: 'Создан',
      dbColumn: 'createdAt',
      listColumn: 'createdAt',
      sortable: true,
      width: 20,
      type: DATA,
    }, {
      label: 'Действия',
      dbColumn: '_id',
      listColumn: 'id',
      sortable: false,
      width: 10,
      type: ACTION,
    }];

    const buttons = [{
      name: 'edit',
      color: 'primary',
      icon: 'edit',
      type: LINK,
      link: '/faqs/',
      text: 'Редактировать',
    }];

    setData(({
      ...data, columns, buttons, status: COMPLETED,
    }));

    return () => {
      faqClearState();
    };
  }, []);

  return (
    <Fragment>
      {data.status === COMPLETED && (
        <Fragment>
          <Row>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Поиск по вопросам</h5>
                  </div>
                  <form className="form gap-20">
                    <Col className="p-0">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Вопрос</span>
                        <div className="form__form-group-field">
                          <Field
                            name="question"
                            component={renderField}
                            type="text"
                            placeholder="Вопрос"
                          />
                        </div>
                      </div>
                    </Col>
                  </form>
                  <ButtonToolbar className="form__button-toolbar wizard__toolbar">
                    <Button color="primary" type="button" onClick={handleFind}>Найти</Button>
                    <Button color="secondary" type="button" onClick={clearFilters}>Очистить</Button>
                  </ButtonToolbar>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <DataTable
                addButton={{ to: '/faqs/create', caption: 'Добавить вопрос' }}
                columns={data.columns}
                buttons={data.buttons}
                getConditions={getConditions}
                getList={getFaqsList}
                list={faqsList}
                pagination={pagination}
                isFind={data.isFind}
                loadListStatus={loadStatus}
                declensions={['вопрос', 'вопроса', 'вопросов']}
              />
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

FaqTable.FORM_NAME = 'faqs_table';

FaqTable.propTypes = {
  getFaqsList: PropTypes.func.isRequired,
  faqClearState: PropTypes.func.isRequired,
  faqClearFlashMessage: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  faqsList: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.any),
  ).isRequired,
  // eslint-disable-next-line react/require-default-props
  pagination: PropTypes.objectOf(PropTypes.any),
  loadStatus: PropTypes.string.isRequired,
  flashMessage: PropTypes.string.isRequired,
  resetForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  faqsList: state.faqs.list,
  pagination: state.faqs.pagination,
  loadStatus: state.faqs.loadListStatus,
  flashMessage: state.faqs.flash,
});

const mapDispatchToProps = dispatch => ({
  getFaqsList: params => dispatch(getFaqsListAction(params)),
  faqClearState: () => dispatch(faqClearStateAction()),
  faqClearFlashMessage: () => dispatch(faqClearFlashMessageAction()),
  resetForm: formName => dispatch(reset(formName)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(withRouter(FaqTable)));

export default reduxForm({
  form: FaqTable.FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(withConnect);
