import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';
import NotificationSystem from 'rc-notification';
import Wizard from './components/WizardForm';

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const showNotification = ({ notification, position }) => {
  switch (position) {
    case 'left-up':
      notificationLU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: position,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
  }
};

class UserEditPage extends PureComponent {
  static propTypes = {
    // t: PropTypes.func.isRequired,
  };

  componentDidMount() {
    NotificationSystem.newInstance({}, (n) => { notificationLU = n; });
    NotificationSystem.newInstance({}, (n) => { notificationRU = n; });
    NotificationSystem.newInstance({}, (n) => { notificationTC = n; });
  }

  componentWillUnmount() {
    notificationLU.destroy();
    notificationRU.destroy();
    notificationTC.destroy();
  }

  render() {
    // const { t } = this.props;

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Пользователи</h3>
            <h3 className="page-subhead subhead">
              Пользователи являются основой обучения людей
            </h3>
          </Col>
        </Row>
        <Wizard showNotification={showNotification} />
      </Container>
    );
  }
}

export default withTranslation('common')(UserEditPage);
