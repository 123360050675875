import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import ArrowUpBold from 'mdi-react/ArrowUpBoldIcon';
import ArrowDownBoldIcon from 'mdi-react/ArrowDownBoldIcon';
import renderSelectField from '../../../shared/components/form/Select';

const importanceField = { width: '100%', display: 'flex' };
const materialStyle = { width: '100%', display: 'flex', paddingLeft: '2px' };

class RenderMaterials extends PureComponent {
  static propTypes = {
    fields: PropTypes.objectOf(PropTypes.any).isRequired,
    materials: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  };

  static moveToUp(fields, index) {
    if (index > 0) {
      fields.swap(index, index - 1);
    }
  }

  static moveToDown(fields, index) {
    if (fields.length - 1 !== index) {
      fields.swap(index, index + 1);
    }
  }

  componentDidMount() {
    const { fields, materials } = this.props;

    if (!fields.length) {
      for (let i = 0; i < materials.length; i += 1) {
        fields.push({ id: materials[i].value, name: materials[i].label, importance: materials[i].importance });
      }
    }
  }

  render() {
    const { fields } = this.props;
    const { moveToUp, moveToDown } = RenderMaterials;

    return (
      <div className="form__form-group">
        {fields.map((material, index) => (
          <div key={fields.get(index).id} className="form__form-group">
            <div style={materialStyle}>
              <strong>Материал:</strong>&nbsp; {fields.get(index).name}
            </div>
            <div style={importanceField}>
              <Field
                name={`${material}.importance`}
                component={renderSelectField}
                type="text"
                options={[
                  { value: 'required', label: 'Обязательно' },
                  { value: 'preferably', label: 'Желательно' },
                  { value: 'general', label: 'Для общего развития' },
                ]}
                placeholder="Выберите обязательность материала"
              />
              <div className="form__form-group-icon">
                <ArrowUpBold onClick={() => moveToUp(fields, index)} />
              </div>
              <div className="form__form-group-icon">
                <ArrowDownBoldIcon onClick={() => moveToDown(fields, index)} />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default RenderMaterials;
