import moment from 'moment';
import { startSubmit, stopSubmit } from 'redux-form';
import ws from '../../ws';
import getToken from './getToken';
import PaginationPageConverter from '../../shared/classes/PaginationPageConverter';

const THEMES_PROGRESS = 'progress';
const THEMES_SUCCESS = 'success';
const THEMES_FAILED = 'failed';

const THEMES_GET_LIST_PROGRESS = 'THEMES_GET_LIST_PROGRESS';
const THEMES_GET_LIST_SUCCESS = 'THEMES_GET_LIST_SUCCESS';
const THEMES_GET_LIST_FAILED = 'THEMES_GET_LIST_FAILED';
const THEMES_FORM_SUBMIT_PROGRESS = 'THEMES_FORM_SUBMIT_PROGRESS';
const THEMES_FORM_SUBMIT_SUCCESS = 'THEMES_FORM_SUBMIT_SUCCESS';
const THEMES_FORM_SUBMIT_FAILED = 'THEMES_FORM_SUBMIT_FAILED';
const THEMES_GET_COURSE_PROGRESS = 'THEMES_GET_COURSE_PROGRESS';
const THEMES_GET_COURSE_SUCCESS = 'THEMES_GET_COURSE_SUCCESS';
const THEMES_GET_COURSE_FAILED = 'THEMES_GET_COURSE_FAILED';
const THEMES_GET_ASYNC_DATA_PROGRESS = 'THEMES_GET_ASYNC_DATA_PROGRESS';
const THEMES_GET_ASYNC_DATA_SUCCESS = 'THEMES_GET_ASYNC_DATA_SUCCESS';
const THEMES_GET_ASYNC_DATA_FAILED = 'THEMES_GET_ASYNC_DATA_FAILED';
const THEMES_CLEAR_STATE = 'THEMES_CLEAR_STATE';

const THEMES_CLEAR_FLASH_MESSAGE = 'THEMES_CLEAR_FLASH_MESSAGE';

const themeGetListProgress = () => ({
  type: THEMES_GET_LIST_PROGRESS,
});

const themeGetListSuccess = payload => ({
  type: THEMES_GET_LIST_SUCCESS,
  payload,
});

const themeGetListFailed = () => ({
  type: THEMES_GET_LIST_FAILED,
});

const themeStartFormSubmit = () => ({
  type: THEMES_FORM_SUBMIT_PROGRESS,
});

const themeFormSubmitSuccess = payload => ({
  type: THEMES_FORM_SUBMIT_SUCCESS,
  payload,
});

const themeFormSubmitFailed = () => ({
  type: THEMES_FORM_SUBMIT_FAILED,
});

const themeGetCourseProgress = () => ({
  type: THEMES_GET_COURSE_PROGRESS,
});

const themeGetCourseSuccess = payload => ({
  type: THEMES_GET_COURSE_SUCCESS,
  payload,
});

const themeGetCourseFailed = () => ({
  type: THEMES_GET_COURSE_FAILED,
});

const themeGetAsyncDataProgress = () => ({
  type: THEMES_GET_ASYNC_DATA_PROGRESS,
});

const themeGetAsyncDataSuccess = payload => ({
  type: THEMES_GET_ASYNC_DATA_SUCCESS,
  payload,
});

const themeGetAsyncDataFailed = () => ({
  type: THEMES_GET_ASYNC_DATA_FAILED,
});

const themeClearState = () => ({
  type: THEMES_CLEAR_STATE,
});

const convertListToTable = data => new PaginationPageConverter(data, doc => ({
  id: doc.id,
  name: doc.name,
  createdAt: moment(doc.createdAt).locale('ru').format('DD MMM YY г., HH:mm'),
})).getConvertedData();

const getThemesList = params => (dispatch) => {
  const token = getToken();
  dispatch(themeGetListProgress());

  ws.emit('api/academy/themes/list', { token, payload: params }, (data) => {
    const { status, payload } = data;
    let action;

    if (status === 'ok') {
      action = themeGetListSuccess(convertListToTable(payload.data));
    } else {
      action = themeGetListFailed();
    }

    dispatch(action);
  });
};

const convertSetThemeToDB = (data) => {
  const {
    name,
    header,
    list,
    objectives,
    tasks,
    extra_tasks: extraTasks,
    checklist,
    dangerlist_header: dangerlistHeader,
    dangerlist,
    attentions,
    backgroundColor,
    themeName,
    themeNameBackground,
    themeDescription,
    themeSub,
    themeSubBackground,
    courseProgress,
    courseProgressCaptions,
  } = data;

  const convertObjectives = (objectiveList) => {
    const objectivesArray = [];

    if (objectiveList && objectiveList.length) {
      for (let i = 0; i < objectiveList.length; i += 1) {
        const objectiveListData = {
          name: objectiveList[i].name,
        };

        if (Object.prototype.hasOwnProperty.call(objectiveList[i], 'id')) {
          /* eslint no-underscore-dangle: 0 */
          objectiveListData._id = objectiveList[i].id;
        }

        objectivesArray.push(objectiveListData);
      }
    }

    return objectivesArray;
  };

  const convertTasks = (taskList) => {
    const themesArray = [];

    if (taskList && taskList.length) {
      for (let i = 0; i < taskList.length; i += 1) {
        themesArray.push(taskList[i].task.value);
      }
    }

    return themesArray;
  };

  const convertCheckList = (checkList) => {
    const checkListArray = [];

    if (checkList && checkList.length) {
      for (let i = 0; i < checkList.length; i += 1) {
        const checkListData = {
          header: checkList[i].header,
        };

        if (Object.prototype.hasOwnProperty.call(checkList[i], 'id')) {
          /* eslint no-underscore-dangle: 0 */
          checkListData._id = checkList[i].id;
        }

        checkListData.contents = [];

        for (let j = 0; j < checkList[i].contents.length; j += 1) {
          const content = {
            content: checkList[i].contents[j].content,
          };

          if (Object.prototype.hasOwnProperty.call(checkList[i].contents[j], 'id')) {
            /* eslint no-underscore-dangle: 0 */
            content._id = checkList[i].contents[j].id;
          }

          checkListData.contents.push(content);
        }

        checkListArray.push(checkListData);
      }
    }

    return checkListArray;
  };

  const convertAttentions = (attentionsList) => {
    const attentionsArray = [];

    if (attentionsList && attentionsList.length) {
      for (let i = 0; i < attentionsList.length; i += 1) {
        const attentionsListData = {
          header: attentionsList[i].header,
          content: attentionsList[i].content,
          icon: null,
        };

        if (Object.prototype.hasOwnProperty.call(attentionsList[i], 'icon')) {
          attentionsListData.icon = typeof attentionsList[i].icon === 'string'
            ? attentionsList[i].icon : attentionsList[i].icon.value;
        }

        if (Object.prototype.hasOwnProperty.call(attentionsList[i], 'id')) {
          /* eslint no-underscore-dangle: 0 */
          attentionsListData._id = attentionsList[i].id;
        }

        attentionsArray.push(attentionsListData);
      }
    }

    return attentionsArray;
  };

  const convertedData = {
    name,
    description: { header, list },
    objectives: convertObjectives(objectives),
    tasks: convertTasks(tasks),
    extra_tasks: convertTasks(extraTasks),
    checklist: convertCheckList(checklist),
    dangerlist_header: dangerlistHeader,
    dangerlist: convertCheckList(dangerlist),
    attentions: convertAttentions(attentions),
  };

  const colors = {};

  if (backgroundColor) {
    colors.background = typeof backgroundColor === 'string' ? backgroundColor : backgroundColor.hex.substr(1);
  }

  if (themeName) {
    colors.themeName = typeof themeName === 'string' ? themeName : themeName.hex.substr(1);
  }

  if (themeNameBackground) {
    colors.themeNameBackground = typeof themeNameBackground === 'string'
      ? themeNameBackground : themeNameBackground.hex.substr(1);
  }

  if (themeDescription) {
    colors.themeDescription = typeof themeDescription === 'string' ? themeDescription : themeDescription.hex.substr(1);
  }

  if (themeSub) {
    colors.themeSub = typeof themeSub === 'string' ? themeSub : themeSub.hex.substr(1);
  }

  if (themeSubBackground) {
    colors.themeSubBackground = typeof themeSubBackground === 'string'
      ? themeSubBackground : themeSubBackground.hex.substr(1);
  }

  if (courseProgress) {
    colors.courseProgress = typeof courseProgress === 'string' ? courseProgress : courseProgress.hex.substr(1);
  }

  if (courseProgressCaptions) {
    colors.courseProgressCaptions = typeof courseProgressCaptions === 'string'
      ? courseProgressCaptions : courseProgressCaptions.hex.substr(1);
  }

  if (Object.keys(colors).length) {
    convertedData.color = colors;
  }

  return convertedData;
};

const generateImageObject = async file => new Promise((resolve) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    resolve({ buffer: e.target.result, type: file.type });
  };

  reader.readAsArrayBuffer(file);
});

const createTheme = async (data, dispatch, props) => {
  const token = getToken();
  const { form, reset } = props;
  const convertedData = convertSetThemeToDB(data);
  const { background, layer, preview } = data;
  const images = {};

  if (background && background.length) {
    images.background = await generateImageObject(background[0]);
  }

  if (layer && layer.length) {
    images.layer = await generateImageObject(layer[0]);
  }

  if (preview && preview.length) {
    images.preview = await generateImageObject(preview[0]);
  }

  dispatch(startSubmit(form));
  dispatch(themeStartFormSubmit());

  ws.emit('api/academy/themes/create', {
    // payload: convertedData, images, token,
    token, payload: { convertedData, images },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      reset();
      dispatch(stopSubmit(form));
      dispatch(themeFormSubmitSuccess('Новая тема была успешно создана.'));
      props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(themeFormSubmitFailed());
    }
  });
};

const convertDBThemeToEdit = (payload) => {
  const { theme: themeFromDB } = payload;
  const { description, image, color } = themeFromDB;

  const theme = {
    id: themeFromDB.id,
    name: themeFromDB.name,
    header: description.header,
    list: description.list,
    objectives: themeFromDB.objectives,
    tasks: themeFromDB.tasks.map(item => ({ task: { value: item.id, label: item.header } })),
    extra_tasks: themeFromDB.extra_tasks.map(item => ({ task: { value: item.id, label: item.header } })),
    checklist: themeFromDB.checklist,
    dangerlist_header: themeFromDB.dangerlist_header,
    dangerlist: themeFromDB.dangerlist,
    attentions: themeFromDB.attentions,
    backgroundColor: color.background,
    themeName: color.themeName,
    themeNameBackground: color.themeNameBackground,
    themeDescription: color.themeDescription,
    themeSub: color.themeSub,
    themeSubBackground: color.themeSubBackground,
    courseProgress: color.courseProgress,
    courseProgressCaptions: color.courseProgressCaptions,
    background: !image.background ? image.background : [{ preview: image.background }],
    layer: !image.layer ? image.layer : [{ preview: image.layer }],
    preview: !image.preview ? image.preview : [{ preview: image.preview }],
  };

  return { theme };
};

const getTheme = themeId => async (dispatch) => {
  const token = getToken();
  dispatch(themeGetCourseProgress());

  ws.emit('api/academy/themes/getById', {
    // themeId, token,
    token, payload: { themeId },
  }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = themeGetCourseSuccess(convertDBThemeToEdit(payload));
    } else {
      action = themeGetCourseFailed();
    }

    dispatch(action);
  });
};

const updateTheme = async (data, dispatch, props) => {
  const token = getToken();
  const {
    id, background, layer, preview,
  } = data;
  const { form } = props;
  const convertedData = convertSetThemeToDB(data);
  const images = {};

  const generateImage = async (file) => {
    let result;

    if (file && file.length && file[0] instanceof File) {
      result = await generateImageObject(file[0]);
    } else if (file && file.length && file[0] instanceof Object) {
      result = true;
    } else {
      result = null;
    }

    return result;
  };

  images.background = await generateImage(background);
  images.layer = await generateImage(layer);
  images.preview = await generateImage(preview);

  dispatch(startSubmit(form));
  dispatch(themeStartFormSubmit());

  ws.emit('api/academy/themes/updateById', {
    // themeId: id, payload: convertedData, images, token,
    token, payload: { themeId: id, convertedData, images },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(stopSubmit(form));
      dispatch(themeFormSubmitSuccess('Тема был успешно отредактирована.'));
      props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(themeFormSubmitFailed());
    }
  });
};

const transformAsyncData = data => ({
  tasks: data.tasks.map(task => ({ value: task.id, label: task.header })),
});

const getAsyncData = () => async (dispatch) => {
  const token = getToken();
  dispatch(themeGetAsyncDataProgress());

  ws.emit('api/academy/themes/getAsyncData', { token }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = themeGetAsyncDataSuccess(transformAsyncData(payload));
    } else {
      action = themeGetAsyncDataFailed();
    }

    dispatch(action);
  });
};

const themeClearFlashMessage = () => ({
  type: THEMES_CLEAR_FLASH_MESSAGE,
});

export {
  THEMES_PROGRESS,
  THEMES_SUCCESS,
  THEMES_FAILED,
  THEMES_GET_LIST_PROGRESS,
  THEMES_GET_LIST_SUCCESS,
  THEMES_GET_LIST_FAILED,
  THEMES_FORM_SUBMIT_PROGRESS,
  THEMES_FORM_SUBMIT_SUCCESS,
  THEMES_FORM_SUBMIT_FAILED,
  THEMES_GET_COURSE_PROGRESS,
  THEMES_GET_COURSE_SUCCESS,
  THEMES_GET_COURSE_FAILED,
  THEMES_GET_ASYNC_DATA_PROGRESS,
  THEMES_GET_ASYNC_DATA_SUCCESS,
  THEMES_GET_ASYNC_DATA_FAILED,
  THEMES_CLEAR_STATE,
  THEMES_CLEAR_FLASH_MESSAGE,
  themeGetListProgress,
  themeGetListSuccess,
  themeGetListFailed,
  themeStartFormSubmit,
  themeFormSubmitSuccess,
  themeFormSubmitFailed,
  themeGetCourseProgress,
  themeGetCourseSuccess,
  themeGetCourseFailed,
  themeGetAsyncDataProgress,
  themeGetAsyncDataSuccess,
  themeGetAsyncDataFailed,
  themeClearState,
  getThemesList,
  createTheme,
  getTheme,
  updateTheme,
  getAsyncData,
  themeClearFlashMessage,
};
