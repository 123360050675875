const validate = (values) => {
  const errors = {};
  const requiredErrorMessage = 'Это поле является обязательным';

  if (!values.name) {
    errors.name = requiredErrorMessage;
  } else if (values.name.length < 3) {
    errors.name = 'Введите не менее 3-х символов';
  }

  if (values.videos && values.videos.length) {
    const videoArrayErrors = [];

    values.videos.forEach((video, videoIndex) => {
      const videoErrors = {};

      if (typeof video.video === 'undefined') {
        videoErrors.video = requiredErrorMessage;
        videoArrayErrors[videoIndex] = videoErrors;
      }
    });

    if (videoArrayErrors.length) {
      errors.videos = videoArrayErrors;
    }
  }

  if (values.audio && values.audio.length) {
    const audioArrayErrors = [];

    values.audio.forEach((audio, audioIndex) => {
      const audioErrors = {};

      if (typeof audio.audio === 'undefined') {
        audioErrors.audio = requiredErrorMessage;
        audioArrayErrors[audioIndex] = audioErrors;
      }
    });

    if (audioArrayErrors.length) {
      errors.audio = audioArrayErrors;
    }
  }

  if (values.customAudio && values.customAudio.length) {
    const customAudioArrayErrors = [];

    values.customAudio.forEach((customAudio, customAudioIndex) => {
      const audioErrors = {};

      if (typeof customAudio.source === 'undefined') {
        audioErrors.source = requiredErrorMessage;
        customAudioArrayErrors[customAudioIndex] = audioErrors;
      }

      if (typeof customAudio.title === 'undefined') {
        audioErrors.title = requiredErrorMessage;
        customAudioArrayErrors[customAudioIndex] = audioErrors;
      }
    });

    if (customAudioArrayErrors.length) {
      errors.customAudio = customAudioArrayErrors;
    }
  }

  if (values.billets && values.billets.length) {
    const billetArrayErrors = [];

    values.billets.forEach((billet, billetIndex) => {
      const billetErrors = {};

      if (typeof billet.button === 'undefined') {
        billetErrors.button = requiredErrorMessage;
        billetArrayErrors[billetIndex] = billetErrors;
      }
    });

    if (billetArrayErrors.length) {
      errors.billets = billetArrayErrors;
    }
  }

  if (values.tasks && values.tasks.length) {
    const taskArrayErrors = [];

    values.tasks.forEach((task, taskIndex) => {
      const taskErrors = {};

      if (typeof task.name === 'undefined') {
        taskErrors.name = requiredErrorMessage;
        taskArrayErrors[taskIndex] = taskErrors;
      }
    });

    if (taskArrayErrors.length) {
      errors.tasks = taskArrayErrors;
    }
  }

  return errors;
};

export default validate;
