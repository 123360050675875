import React from 'react';
import PropTypes from 'prop-types';

const hiddenField = ({ input }) => (
  <input {...input} type="hidden" />
);

hiddenField.propTypes = {
  input: PropTypes.shape().isRequired,
};

export default hiddenField;
