import React from 'react';
import PropTypes from 'prop-types';
import CKEditor from 'ckeditor4-react';

const TinyWYSIWYGEditor = ({
  input,
}) => (
  <CKEditor
    data={input.value}
    config={{
      width: '100%',
      extraAllowedContent: [
        'span{color,background-color}', 'p{text-align}',
      ],
      toolbar: [
        ['Cut', 'Copy', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo', '-', 'Bold', 'Italic', 'Underline', 'Strike',
          '-', 'RemoveFormat', '-', 'NumberedList', 'BulletedList', '-', 'Link', 'Unlink', '-', 'Maximize',
          'ShowBlocks', '-', 'Source'],
      ],
    }}
    onChange={
      event => input.onChange(event.editor.getData())
    }
    // eslint-disable-next-line
    onBeforeLoad={CKEDITOR => (CKEDITOR.disableAutoInline = true)}
  />
);

TinyWYSIWYGEditor.propTypes = {
  input: PropTypes.shape().isRequired,
};

export default TinyWYSIWYGEditor;
