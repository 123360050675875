import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Card, CardBody, Col, ButtonToolbar,
} from 'reactstrap';
import { FieldArray, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  PROLONGATION_SUCCESS, PROLONGATION_FAILED,
  getProlongation as getProlongationAction,
  prolongationClearState as prolongationClearStateAction,
  updateProlongation as updateProlongationAction,
} from '../../../../redux/actions/prolongationAction';
import ButtonToNavigate from '../../../../shared/components/form/ButtonToNavigate';
import { FullWideNotification } from '../../../../shared/components/Notification';
import RenderProlongation from '../../components/RenderProlongation';
import validate from '../../validate';

class EditForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    submitStatus: PropTypes.string.isRequired,
    activateStatus: PropTypes.string.isRequired,
    loadStatus: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    getProlongation: PropTypes.func.isRequired,
    prolongationClearState: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { getProlongation, match } = this.props;
    const { userId } = match.params;

    getProlongation(userId);
  }

  componentWillReceiveProps(nextProps) {
    const { submitStatus, loadStatus, history } = nextProps;

    if (loadStatus === PROLONGATION_FAILED) {
      history.push('/404');
    }

    if (submitStatus === PROLONGATION_SUCCESS) {
      history.push('/users');
    } else if (submitStatus === PROLONGATION_FAILED) {
      this.show('danger', 'Произошла непредвиденная ошибка. Обратитесь к разработчикам.');
    }
  }

  componentWillUnmount() {
    const { prolongationClearState } = this.props;
    prolongationClearState();
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  render() {
    const {
      // eslint-disable-next-line react/prop-types
      handleSubmit, loadStatus, match, showNotification, activateStatus,
    } = this.props;
    const { userId } = match.params;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">КУРСЫ ПОЛЬЗОВАТЕЛЯ</h5>
              <h5 className="subhead">&nbsp;</h5>
            </div>
            {loadStatus === PROLONGATION_SUCCESS ? (
              <form className="form" target="_blank" onSubmit={handleSubmit}>
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <FieldArray
                      name="prolongation"
                      component={RenderProlongation}
                      props={{ options: { userId, showNotification, activateStatus } }}
                    />
                  </div>
                </div>
                <ButtonToolbar className="form__button-toolbar">
                  <Route
                    path="/"
                    render={props => <ButtonToNavigate {...props} title="Отмена" path="/users" />}
                  />
                </ButtonToolbar>
              </form>
            ) : null}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: state.prolongation.active,
  submitStatus: state.prolongation.submitStatus,
  activateStatus: state.prolongation.activateStatus,
  loadStatus: state.prolongation.loadActiveStatus,
});

const mapDispatchToProps = dispatch => ({
  getProlongation: userId => dispatch(getProlongationAction(userId)),
  prolongationClearState: () => dispatch(prolongationClearStateAction()),
});

const EditFormComponent = reduxForm({
  form: 'prolongation_edit_form', // a unique identifier for this form
  validate,
  enableReinitialize: true,
  onSubmit: updateProlongationAction,
})(withTranslation('common')(withRouter(EditForm)));

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditFormComponent);
