import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import ImageTablePage from '../../../Images/ImageTable/index';
import ImageCreatePage from '../../../Images/ImageCreate/index';
import ImageEditPage from '../../../Images/ImageEdit/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/images" exact component={ImageTablePage} />
    <ProtectedRoute path="/images/create" component={ImageCreatePage} />
    <ProtectedRoute path="/images/:imageId" exact component={ImageEditPage} />
  </Switch>
);
