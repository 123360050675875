import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field } from 'redux-form';
import ArrowUpBold from 'mdi-react/ArrowUpBoldIcon';
import ArrowDownBoldIcon from 'mdi-react/ArrowDownBoldIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import renderSelectField from '../../../shared/components/form/Select';

const buttonTop = {
  marginTop: '15px',
};

const paddingLeft = {
  paddingLeft: '30px',
};

class RenderThemes extends PureComponent {
  static propTypes = {
    fields: PropTypes.objectOf(PropTypes.any).isRequired,
    meta: PropTypes.objectOf(PropTypes.any).isRequired,
    options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  };

  static defaultProps = {
    options: null,
  };

  static moveToUp(fields, index) {
    if (index > 0) {
      fields.swap(index, index - 1);
    }
  }

  static moveToDown(fields, index) {
    if (fields.length - 1 !== index) {
      fields.swap(index, index + 1);
    }
  }

  isDisabledAddButton = () => {
    const { options } = this.props;
    return !options.length;
  };

  render() {
    const { fields, options, meta: { error, submitFailed } } = this.props;
    const { moveToUp, moveToDown } = RenderThemes;

    return (
      <div className="form__form-group">
        <ButtonToolbar style={buttonTop}>
          <Button
            color="success"
            size="sm"
            disabled={this.isDisabledAddButton()}
            onClick={() => fields.push({})}
          >
            Добавить тему
          </Button>
        </ButtonToolbar>
        {submitFailed && error
        && (
          <div className="form__form-group">
            <div>
              <span className="form__form-group-error">{error}</span>
            </div>
          </div>
        )}
        {fields.map((theme, index) => (
          <div key={index} className="form__form-group" style={paddingLeft}>
            <span className="form__form-group-label">Тема курса # {index + 1}</span>
            <div className="form__form-group-field">
              <Field
                name={`${theme}.theme`}
                component={renderSelectField}
                type="text"
                placeholder="Выберите тему"
                options={options}
              />
              <div className="form__form-group-icon">
                <ArrowUpBold onClick={() => moveToUp(fields, index)} />
              </div>
              <div className="form__form-group-icon">
                <ArrowDownBoldIcon onClick={() => moveToDown(fields, index)} />
              </div>
              <div className="form__form-group-icon">
                <TrashCanOutlineIcon onClick={() => fields.remove(index)} />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default RenderThemes;
