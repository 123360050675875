const validate = (values) => {
  // eslint-disable-next-line
  const errors = {};
  const requiredErrorMessage = 'Это поле является обязательным';

  if (!values.maraphonFlow) {
    errors.maraphonFlow = requiredErrorMessage;
  }

  if (!values.sort) {
    errors.sort = requiredErrorMessage;
  }

  if (!values.price) {
    errors.price = requiredErrorMessage;
  } else if (parseInt(values.price, 10) <= 0) {
    errors.price = 'Введите значение больше 0';
  }

  if (!values.productId) {
    errors.productId = requiredErrorMessage;
  } else if (values.productId.length !== 24) {
    errors.productId = 'Некорректный идентификатор';
  }

  if (typeof values.discountIsEnable === 'boolean' && values.discountIsEnable) {
    if (!values.discountPrice) {
      errors.discountPrice = requiredErrorMessage;
    } else if (parseInt(values.discountPrice, 10) <= 0) {
      errors.discountPrice = 'Введите значение больше 0';
    }

    if (!values.discountPercent) {
      errors.discountPercent = requiredErrorMessage;
    } else if (parseInt(values.discountPercent, 10) <= 0 || parseInt(values.discountPercent, 10) > 100) {
      errors.discountPercent = 'Значение должно быть от 1 до 100';
    }
  }

  return errors;
};

export default validate;
