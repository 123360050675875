import moment from 'moment';
import { startSubmit, stopSubmit } from 'redux-form';
import ws from '../../ws';
import getToken from './getToken';
import PaginationPageConverter from '../../shared/classes/PaginationPageConverter';

const QUESTIONS_PROGRESS = 'progress';
const QUESTIONS_SUCCESS = 'success';
const QUESTIONS_FAILED = 'failed';

const QUESTIONS_GET_LIST_PROGRESS = 'QUESTIONS_GET_LIST_PROGRESS';
const QUESTIONS_GET_LIST_SUCCESS = 'QUESTIONS_GET_LIST_SUCCESS';
const QUESTIONS_GET_LIST_FAILED = 'QUESTIONS_GET_LIST_FAILED';
const QUESTIONS_FORM_SUBMIT_PROGRESS = 'QUESTIONS_FORM_SUBMIT_PROGRESS';
const QUESTIONS_FORM_SUBMIT_SUCCESS = 'QUESTIONS_FORM_SUBMIT_SUCCESS';
const QUESTIONS_FORM_SUBMIT_FAILED = 'QUESTIONS_FORM_SUBMIT_FAILED';
const QUESTIONS_GET_QUESTION_PROGRESS = 'QUESTIONS_GET_QUESTION_PROGRESS';
const QUESTIONS_GET_QUESTION_SUCCESS = 'QUESTIONS_GET_QUESTION_SUCCESS';
const QUESTIONS_GET_QUESTION_FAILED = 'QUESTIONS_GET_QUESTION_FAILED';
const QUESTIONS_GET_ASYNC_DATA_PROGRESS = 'QUESTIONS_GET_ASYNC_DATA_PROGRESS';
const QUESTIONS_GET_ASYNC_DATA_SUCCESS = 'QUESTIONS_GET_ASYNC_DATA_SUCCESS';
const QUESTIONS_GET_ASYNC_DATA_FAILED = 'QUESTIONS_GET_ASYNC_DATA_FAILED';
const QUESTIONS_REFRESH_ASYNC_DATA_PROGRESS = 'QUESTIONS_REFRESH_ASYNC_DATA_PROGRESS';
const QUESTIONS_REFRESH_ASYNC_DATA_SUCCESS = 'QUESTIONS_REFRESH_ASYNC_DATA_SUCCESS';
const QUESTIONS_REFRESH_ASYNC_DATA_FAILED = 'QUESTIONS_REFRESH_ASYNC_DATA_FAILED';
const QUESTIONS_UPDATE_EDIT_TIME_PROGRESS = 'QUESTIONS_UPDATE_EDIT_TIME_PROGRESS';
const QUESTIONS_UPDATE_EDIT_TIME_SUCCESS = 'QUESTIONS_UPDATE_EDIT_TIME_SUCCESS';
const QUESTIONS_UPDATE_EDIT_TIME_FAILED = 'QUESTIONS_UPDATE_EDIT_TIME_FAILED';
const QUESTIONS_SET_JUST_SAVE = 'QUESTIONS_SET_JUST_SAVE';
const QUESTIONS_DELETE = 'QUESTIONS_DELETE';
const QUESTIONS_SEND_TO_TECHNICAL = 'QUESTIONS_SEND_TO_TECHNICAL';
const QUESTIONS_CLOSE_QUESTION = 'QUESTIONS_CLOSE_QUESTION';
const QUESTIONS_GET_STATISTICS_ASYNC_DATA_PROGRESS = 'QUESTIONS_GET_STATISTICS_ASYNC_DATA_PROGRESS';
const QUESTIONS_GET_STATISTICS_ASYNC_DATA_SUCCESS = 'QUESTIONS_GET_STATISTICS_ASYNC_DATA_SUCCESS';
const QUESTIONS_GET_STATISTICS_ASYNC_DATA_FAILED = 'QUESTIONS_GET_STATISTICS_ASYNC_DATA_FAILED';
const QUESTIONS_GET_STATISTICS_PROGRESS = 'QUESTIONS_GET_STATISTICS_PROGRESS';
const QUESTIONS_GET_STATISTICS_SUCCESS = 'QUESTIONS_GET_STATISTICS_SUCCESS';
const QUESTIONS_GET_STATISTICS_FAILED = 'QUESTIONS_GET_STATISTICS_FAILED';
const QUESTIONS_CLEAR_STATE = 'QUESTIONS_CLEAR_STATE';

const QUESTIONS_CLEAR_FLASH_MESSAGE = 'QUESTIONS_CLEAR_FLASH_MESSAGE';

const questionGetListProgress = () => ({
  type: QUESTIONS_GET_LIST_PROGRESS,
});

const questionGetListSuccess = payload => ({
  type: QUESTIONS_GET_LIST_SUCCESS,
  payload,
});

const questionGetListFailed = () => ({
  type: QUESTIONS_GET_LIST_FAILED,
});

const questionStartFormSubmit = () => ({
  type: QUESTIONS_FORM_SUBMIT_PROGRESS,
});

const questionFormSubmitSuccess = payload => ({
  type: QUESTIONS_FORM_SUBMIT_SUCCESS,
  payload,
});

const questionFormSubmitFailed = () => ({
  type: QUESTIONS_FORM_SUBMIT_FAILED,
});

const questionGetQuestionProgress = () => ({
  type: QUESTIONS_GET_QUESTION_PROGRESS,
});

const questionGetQuestionSuccess = payload => ({
  type: QUESTIONS_GET_QUESTION_SUCCESS,
  payload,
});

const questionGetQuestionFailed = () => ({
  type: QUESTIONS_GET_QUESTION_FAILED,
});

const questionGetAsyncDataProgress = () => ({
  type: QUESTIONS_GET_ASYNC_DATA_PROGRESS,
});

const questionGetAsyncDataSuccess = payload => ({
  type: QUESTIONS_GET_ASYNC_DATA_SUCCESS,
  payload,
});

const questionGetAsyncDataFailed = () => ({
  type: QUESTIONS_GET_ASYNC_DATA_FAILED,
});

const questionRefreshAsyncDataProgress = () => ({
  type: QUESTIONS_REFRESH_ASYNC_DATA_PROGRESS,
});

const questionRefreshAsyncDataSuccess = payload => ({
  type: QUESTIONS_REFRESH_ASYNC_DATA_SUCCESS,
  payload,
});

const questionRefreshAsyncDataFailed = () => ({
  type: QUESTIONS_REFRESH_ASYNC_DATA_FAILED,
});

const questionUpdateEditTimeProgress = () => ({
  type: QUESTIONS_UPDATE_EDIT_TIME_PROGRESS,
});

const questionUpdateEditTimeSuccess = () => ({
  type: QUESTIONS_UPDATE_EDIT_TIME_SUCCESS,
});

const questionUpdateEditTimeFailed = () => ({
  type: QUESTIONS_UPDATE_EDIT_TIME_FAILED,
});

const questionClearState = () => ({
  type: QUESTIONS_CLEAR_STATE,
});

const questionSetJustSave = () => ({
  type: QUESTIONS_SET_JUST_SAVE,
});

const questionDelete = () => ({
  type: QUESTIONS_DELETE,
});

const questionSendToTechnical = () => ({
  type: QUESTIONS_SEND_TO_TECHNICAL,
});

const questionCloseQuestion = () => ({
  type: QUESTIONS_CLOSE_QUESTION,
});

const questionGetStatisticsAsyncDataProgress = () => ({
  type: QUESTIONS_GET_STATISTICS_ASYNC_DATA_PROGRESS,
});

const questionGetStatisticsAsyncDataSuccess = payload => ({
  type: QUESTIONS_GET_STATISTICS_ASYNC_DATA_SUCCESS,
  payload,
});

const questionGetStatisticsAsyncDataFailed = () => ({
  type: QUESTIONS_GET_STATISTICS_ASYNC_DATA_FAILED,
});

const questionGetStatisticsProgress = () => ({
  type: QUESTIONS_GET_STATISTICS_PROGRESS,
});

const questionGetStatisticsSuccess = payload => ({
  type: QUESTIONS_GET_STATISTICS_SUCCESS,
  payload,
});

const questionGetStatisticsFailed = () => ({
  type: QUESTIONS_GET_STATISTICS_FAILED,
});

const convertListToTable = data => new PaginationPageConverter(data, doc => ({
  id: doc.id,
  title: doc.title,
  status: doc.status,
  name: doc.user !== null ? doc.user.name : 'не определен',
  flow: doc.flow || 'не определен',
  createdAt: moment(doc.createdAt).locale('ru').format('DD MMM YY г., HH:mm'),
})).getConvertedData();

const getQuestionsList = params => (dispatch) => {
  const token = getToken();
  dispatch(questionGetListProgress());

  ws.emit('api/academy/questions/list', { token, payload: params }, (data) => {
    const { status, payload } = data;
    let action;

    if (status === 'ok') {
      action = questionGetListSuccess(convertListToTable(payload.data));
    } else {
      action = questionGetListFailed();
    }

    dispatch(action);
  });
};

const convertSetQuestionToDB = (data) => {
  const {
    title, question, answer, tags, academy, name, lastQuestionId, lastAnswerId,
  } = data;
  const videos = Object.prototype.hasOwnProperty.call(data, 'videos') ? data.videos : [];

  return {
    title,
    question,
    answer,
    tags: tags ? [...new Set(tags.map(tag => tag.tag.value))] : [],
    videos: videos.map(video => video.video),
    academy: academy.map(item => ({
      course: item.course.value,
      themes: [...new Set(item.themes.map(theme => theme.theme.value))],
    })),
    name: !name ? null : name,
    lastQuestionId,
    lastAnswerId,
  };
};

const createQuestion = async (data, dispatch, props) => {
  const token = getToken();
  const { form, reset } = props;
  const convertedData = convertSetQuestionToDB(data);

  dispatch(startSubmit(form));
  dispatch(questionStartFormSubmit());

  ws.emit('api/academy/questions/create', {
    // payload: convertedData, token,
    token, payload: { convertedData },
  }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      reset();
      dispatch(stopSubmit(form));
      dispatch(questionFormSubmitSuccess('Новый вопрос был успешно создан.'));
      props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(questionFormSubmitFailed());
    }
  });
};

const convertDBQuestionToEdit = (payload) => {
  const {
    question: questionFromDB, fullQuestion, lastAnswer, lastQuestion, isShowDialog, isEditQuestion, questionEditId,
  } = payload;

  const question = {
    id: questionFromDB.id,
    title: questionFromDB.title,
    name: questionFromDB.name,
    user: questionFromDB.user.name,
    question: lastQuestion.content,
    lastQuestionId: lastQuestion.id,
    tags: questionFromDB.tags.map(item => ({ tag: { value: item.id, label: item.name } })),
    academy: questionFromDB.academy.map(item => ({
      course: { value: item.course.id, label: item.course.name },
      themes: item.themes.map(theme => ({ theme: { value: theme.id, label: theme.name } })),
    })),
    isShowDialogue: isShowDialog,
    isEditQuestion,
    questionEditId,
  };

  if (!fullQuestion) {
    question.list = [];
  } else {
    question.list = fullQuestion.list.map((item) => {
      const record = item;

      record.question.content = record.question.content.replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/\n/g, '<br />');

      record.question.content = `<p>${record.question.content}</p>`;

      if (record.answer.content === null) {
        record.answer.content = '<p><em>[ ответ отсутствует ]</em></p>';
      }

      return record;
    });
  }

  if (!lastAnswer) {
    question.answer = null;
    question.videos = [];
    question.lastAnswerId = null;
  } else {
    const { videos } = lastAnswer;

    question.answer = lastAnswer.content;
    question.videos = videos.map(video => ({ video }));
    question.lastAnswerId = lastAnswer.id;
  }

  return { question };
};

const getQuestion = questionId => async (dispatch) => {
  const token = getToken();
  dispatch(questionGetQuestionProgress());

  ws.emit('api/academy/questions/getById', {
    // questionId, token,
    token, payload: { questionId },
  }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = questionGetQuestionSuccess(convertDBQuestionToEdit(payload));
    } else {
      action = questionGetQuestionFailed();
    }

    dispatch(action);
  });
};

const updateQuestion = async (data, dispatch, props) => {
  const token = getToken();
  const { id } = data;
  const {
    form, justSave, isDelete, isSendToTechnical, closeQuestion,
  } = props;
  const convertedData = convertSetQuestionToDB(data);

  dispatch(startSubmit(form));
  dispatch(questionStartFormSubmit());

  if (isDelete) {
    ws.emit('api/academy/questions/delete', {
      // questionId: id, payload: convertedData, token,
      token, payload: { questionId: id, convertedData },
    }, (result) => {
      const { status } = result;

      if (status === 'ok') {
        dispatch(stopSubmit(form));
        dispatch(questionFormSubmitSuccess(
          'Вопрос был удален и пользователелю было отправлено сообщение на E-mail',
        ));
        props.destroy();
      } else {
        dispatch(stopSubmit(form, 'error'));
        dispatch(questionFormSubmitFailed());
      }
    });
  } else if (isSendToTechnical) {
    ws.emit('api/academy/questions/resendToTechnical', {
      // questionId: id, payload: convertedData, token,
      token, payload: { questionId: id, convertedData },
    }, (result) => {
      const { status } = result;

      if (status === 'ok') {
        dispatch(stopSubmit(form));
        dispatch(questionFormSubmitSuccess(
          'Вопрос был удален и перенаправлен в службу техничесой поддержки',
        ));
        props.destroy();
      } else {
        dispatch(stopSubmit(form, 'error'));
        dispatch(questionFormSubmitFailed());
      }
    });
  } else {
    ws.emit('api/academy/questions/updateById', {
      // questionId: id, payload: convertedData, justSave, token,
      token,
      payload: {
        questionId: id, convertedData, justSave, closeQuestion,
      },
    }, (result) => {
      const { status } = result;

      if (status === 'ok') {
        dispatch(stopSubmit(form));
        // eslint-disable-next-line no-nested-ternary
        const message = !closeQuestion
          ? (justSave
            ? 'Ответ был успешно опубликован.'
            : 'Ответ был успешно опубликован и пользователь был уведомлен по E-mail об ответе.')
          : 'Вопрос был успешно закрыт.';
        dispatch(questionFormSubmitSuccess(message));
        props.destroy();
      } else {
        dispatch(stopSubmit(form, 'error'));
        dispatch(questionFormSubmitFailed());
      }
    });
  }
};

const transformAsyncData = data => ({
  tags: data.tags.map(tag => ({ value: tag.id, label: tag.name })),
  courses: data.courses.map(item => ({
    course: { value: item.id, label: item.name },
    themes: item.themes.map(theme => ({ value: theme.id, label: theme.name })),
  })),
  maraphons: data.maraphons.map(item => ({
    course: { value: item.id, label: item.name },
    themes: item.tasks.map(task => ({ value: task.id, label: task.name })),
  })),
});

const getAsyncData = () => async (dispatch) => {
  const token = getToken();
  dispatch(questionGetAsyncDataProgress());

  ws.emit('api/academy/questions/getAsyncData', { token }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = questionGetAsyncDataSuccess(transformAsyncData(payload));
    } else {
      action = questionGetAsyncDataFailed();
    }

    dispatch(action);
  });
};

const transformAsyncRefreshData = data => ({
  tags: data.tags.map(tag => ({ value: tag.id, label: tag.name })),
});

const refreshAsyncData = () => async (dispatch) => {
  const token = getToken();
  dispatch(questionRefreshAsyncDataProgress());

  ws.emit('api/academy/questions/refreshAsyncData', { token }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = questionRefreshAsyncDataSuccess(transformAsyncRefreshData(payload));
    } else {
      action = questionRefreshAsyncDataFailed();
    }

    dispatch(action);
  });
};

const questionClearFlashMessage = () => ({
  type: QUESTIONS_CLEAR_FLASH_MESSAGE,
});

const setJustSave = () => (dispatch) => {
  dispatch(questionSetJustSave());
};

const deleteQuestion = () => (dispatch) => {
  dispatch(questionDelete());
};

const sendToTechnical = () => (dispatch) => {
  dispatch(questionSendToTechnical());
};

const closeQuestion = () => (dispatch) => {
  dispatch(questionCloseQuestion());
};

const updateEditTime = editId => async (dispatch) => {
  const token = getToken();

  dispatch(questionUpdateEditTimeProgress());

  ws.emit('api/academy/questions/updateEditTime', {
    // editId, token,
    token, payload: { editId },
  }, (result) => {
    const { status } = result;
    let action;

    if (status === 'ok') {
      action = questionUpdateEditTimeSuccess();
    } else {
      action = questionUpdateEditTimeFailed();
    }

    dispatch(action);
  });
};

const getStatisticsAsyncData = () => async (dispatch) => {
  const token = getToken();
  dispatch(questionGetStatisticsAsyncDataProgress());

  ws.emit('api/academy/questions/getStatisticsAsyncData', { token }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = questionGetStatisticsAsyncDataSuccess(payload);
    } else {
      action = questionGetStatisticsAsyncDataFailed();
    }

    dispatch(action);
  });
};

const convertSetStatisticsToDB = (data) => {
  const { begin, end, curators } = data;

  return {
    begin: begin.toJSON(),
    end: end.toJSON(),
    curators: typeof curators === 'undefined' ? [] : Object.keys(curators).reduce((array, id) => {
      if (curators[id]) {
        array.push(id);
      }

      return array;
    }, []),
  };
};

const getStatistics = async (data, dispatch, props) => {
  const token = getToken();
  const { form } = props;
  const convertedData = convertSetStatisticsToDB(data);

  dispatch(startSubmit(form));
  dispatch(questionGetStatisticsProgress());

  ws.emit('api/academy/questions/statistics', { token, payload: { ...convertedData } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      // reset();
      dispatch(stopSubmit(form));
      dispatch(questionGetStatisticsSuccess(payload));
      // props.destroy();
    } else {
      dispatch(stopSubmit(form, 'error'));
      dispatch(questionGetStatisticsFailed());
    }
  });
};

export {
  QUESTIONS_PROGRESS,
  QUESTIONS_SUCCESS,
  QUESTIONS_FAILED,
  QUESTIONS_GET_LIST_PROGRESS,
  QUESTIONS_GET_LIST_SUCCESS,
  QUESTIONS_GET_LIST_FAILED,
  QUESTIONS_FORM_SUBMIT_PROGRESS,
  QUESTIONS_FORM_SUBMIT_SUCCESS,
  QUESTIONS_FORM_SUBMIT_FAILED,
  QUESTIONS_GET_QUESTION_PROGRESS,
  QUESTIONS_GET_QUESTION_SUCCESS,
  QUESTIONS_GET_QUESTION_FAILED,
  QUESTIONS_GET_ASYNC_DATA_PROGRESS,
  QUESTIONS_GET_ASYNC_DATA_SUCCESS,
  QUESTIONS_GET_ASYNC_DATA_FAILED,
  QUESTIONS_REFRESH_ASYNC_DATA_PROGRESS,
  QUESTIONS_REFRESH_ASYNC_DATA_SUCCESS,
  QUESTIONS_REFRESH_ASYNC_DATA_FAILED,
  QUESTIONS_UPDATE_EDIT_TIME_PROGRESS,
  QUESTIONS_UPDATE_EDIT_TIME_SUCCESS,
  QUESTIONS_UPDATE_EDIT_TIME_FAILED,
  QUESTIONS_SET_JUST_SAVE,
  QUESTIONS_DELETE,
  QUESTIONS_SEND_TO_TECHNICAL,
  QUESTIONS_CLOSE_QUESTION,
  QUESTIONS_GET_STATISTICS_ASYNC_DATA_PROGRESS,
  QUESTIONS_GET_STATISTICS_ASYNC_DATA_SUCCESS,
  QUESTIONS_GET_STATISTICS_ASYNC_DATA_FAILED,
  QUESTIONS_GET_STATISTICS_PROGRESS,
  QUESTIONS_GET_STATISTICS_SUCCESS,
  QUESTIONS_GET_STATISTICS_FAILED,
  QUESTIONS_CLEAR_STATE,
  QUESTIONS_CLEAR_FLASH_MESSAGE,
  questionGetListProgress,
  questionGetListSuccess,
  questionGetListFailed,
  questionStartFormSubmit,
  questionFormSubmitSuccess,
  questionFormSubmitFailed,
  questionGetQuestionProgress,
  questionGetQuestionSuccess,
  questionGetQuestionFailed,
  questionGetAsyncDataProgress,
  questionGetAsyncDataSuccess,
  questionGetAsyncDataFailed,
  questionRefreshAsyncDataProgress,
  questionRefreshAsyncDataSuccess,
  questionRefreshAsyncDataFailed,
  questionUpdateEditTimeProgress,
  questionUpdateEditTimeSuccess,
  questionUpdateEditTimeFailed,
  questionClearState,
  questionGetStatisticsAsyncDataProgress,
  questionGetStatisticsAsyncDataSuccess,
  questionGetStatisticsAsyncDataFailed,
  questionGetStatisticsProgress,
  questionGetStatisticsSuccess,
  questionGetStatisticsFailed,
  getQuestionsList,
  createQuestion,
  getQuestion,
  updateQuestion,
  getAsyncData,
  refreshAsyncData,
  questionClearFlashMessage,
  setJustSave,
  deleteQuestion,
  sendToTechnical,
  closeQuestion,
  updateEditTime,
  getStatisticsAsyncData,
  getStatistics,
};
