import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { FieldArray, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import RenderPackages from '../../components/RenderPackages';
import validate from '../../validate';

class WizardFormTwo extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    asyncData: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  render() {
    const { handleSubmit, previousPage, asyncData } = this.props;
    const { instantFlows } = asyncData;

    return (
      <form className="form form--horizontal wizard__form wizard__form__lg" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Создайте пакеты для потока</h3>
        <FieldArray name="packages" component={RenderPackages} props={{ options: { instantFlows } }} />
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}>Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Следующий шаг</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'maraphon_flows_create_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  pure: false,
  validate,
})(WizardFormTwo);
