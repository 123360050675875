import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import FaqTablePage from '../../../Faqs/FaqTable/index';
import FaqCreatePage from '../../../Faqs/FaqCreate/index';
import FaqEditPage from '../../../Faqs/FaqEdit/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/faqs" exact component={FaqTablePage} />
    <ProtectedRoute path="/faqs/create" component={FaqCreatePage} />
    <ProtectedRoute path="/faqs/:faqId" exact component={FaqEditPage} />
  </Switch>
);
