import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import store from './store';
import { AUTH_AUTHENTICATED } from '../../redux/types/authTypes';

const isAuthenticated = () => {
  const { status } = store.getState().auth;
  return status === AUTH_AUTHENTICATED;
};

const ProtectedRoute = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />
    )}
  />
);

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default ProtectedRoute;
