import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { Col, Card, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { destroy } from 'redux-form';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  HUBS_SUCCESS, HUBS_FAILED, hubClearState as hubClearStateAction, getAsyncData as getAsyncDataAction,
  getHub as getHubAction,
} from '../../../../redux/actions/hubsAction';
import WizardFormOne from './WizardFormOne';
import WizardFormTwo from './WizardFormTwo';
import { FullWideNotification } from '../../../../shared/components/Notification';

class WizardForm extends PureComponent {
  static propTypes = {
    initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    asyncData: PropTypes.objectOf(PropTypes.any).isRequired,
    asyncStatus: PropTypes.string.isRequired,
    submitStatus: PropTypes.string.isRequired,
    loadStatus: PropTypes.string.isRequired,
    showNotification: PropTypes.func.isRequired,
    getAsyncData: PropTypes.func.isRequired,
    hubClearState: PropTypes.func.isRequired,
    getHub: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      page: 1,
    };
  }

  componentDidMount() {
    const {
      // eslint-disable-next-line react/prop-types
      getAsyncData, getHub, match, reinitializeReduxForm,
    } = this.props;
    const { hubId } = match.params;

    reinitializeReduxForm();

    getAsyncData();
    getHub(hubId);
  }

  componentWillReceiveProps(nextProps) {
    const { submitStatus, loadStatus, history } = nextProps;

    if (loadStatus === HUBS_FAILED) {
      history.push('/404');
    }

    if (submitStatus === HUBS_SUCCESS) {
      history.push('/hubs');
    } else if (submitStatus === HUBS_FAILED) {
      this.show('danger', 'Произошла непредвиденная ошибка. Обратитесь к разработчикам.');
    }
  }

  componentWillUnmount() {
    const { hubClearState } = this.props;
    hubClearState();
  }

  show = (color, message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color={color}
        message={message}
      />,
      position: 'full',
    });
  };

  nextPage = () => {
    this.setState(prevState => ({ page: prevState.page + 1 }));
  };

  previousPage = () => {
    this.setState(prevState => ({ page: prevState.page - 1 }));
  };

  render() {
    const {
      asyncData, asyncStatus, loadStatus, initialValues,
    } = this.props; // showNotification
    const { page } = this.state;

    return (
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <div className="wizard">
              <div className="wizard__steps">
                <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}><p>Шаг 1</p></div>
                <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}><p>Шаг 2</p></div>
              </div>
              {(asyncStatus === HUBS_SUCCESS && loadStatus === HUBS_SUCCESS)
              && (
              <div className="wizard__form-wrapper">
                {page === 1
                && (
                  <WizardFormOne
                    onSubmit={this.nextPage}
                    initialValues={initialValues}
                  />
                )}
                {page === 2
                && (
                  <WizardFormTwo
                    previousPage={this.previousPage}
                    asyncData={asyncData}
                  />
                )}
              </div>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: state.hubs.active,
  asyncData: state.hubs.asyncData,
  asyncStatus: state.hubs.asyncStatus,
  submitStatus: state.hubs.submitStatus,
  loadStatus: state.hubs.loadActiveStatus,
});

const mapDispatchToProps = dispatch => ({
  getHub: hubId => dispatch(getHubAction(hubId)),
  getAsyncData: () => dispatch(getAsyncDataAction()),
  hubClearState: () => dispatch(hubClearStateAction()),
  reinitializeReduxForm: () => dispatch(destroy('hubs_edit_form')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(withRouter(WizardForm)));
