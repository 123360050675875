import update from 'immutability-helper';
import {
  QUESTIONS_PROGRESS,
  QUESTIONS_SUCCESS,
  QUESTIONS_FAILED,
  QUESTIONS_GET_LIST_PROGRESS,
  QUESTIONS_GET_LIST_SUCCESS,
  QUESTIONS_GET_LIST_FAILED,
  QUESTIONS_FORM_SUBMIT_PROGRESS,
  QUESTIONS_FORM_SUBMIT_SUCCESS,
  QUESTIONS_FORM_SUBMIT_FAILED,
  QUESTIONS_GET_QUESTION_PROGRESS,
  QUESTIONS_GET_QUESTION_SUCCESS,
  QUESTIONS_GET_QUESTION_FAILED,
  QUESTIONS_GET_ASYNC_DATA_PROGRESS,
  QUESTIONS_GET_ASYNC_DATA_SUCCESS,
  QUESTIONS_GET_ASYNC_DATA_FAILED,
  QUESTIONS_REFRESH_ASYNC_DATA_PROGRESS,
  QUESTIONS_REFRESH_ASYNC_DATA_SUCCESS,
  QUESTIONS_REFRESH_ASYNC_DATA_FAILED,
  QUESTIONS_UPDATE_EDIT_TIME_PROGRESS,
  QUESTIONS_UPDATE_EDIT_TIME_SUCCESS,
  QUESTIONS_UPDATE_EDIT_TIME_FAILED,
  QUESTIONS_SET_JUST_SAVE,
  QUESTIONS_DELETE,
  QUESTIONS_SEND_TO_TECHNICAL,
  QUESTIONS_CLOSE_QUESTION,
  QUESTIONS_GET_STATISTICS_ASYNC_DATA_PROGRESS,
  QUESTIONS_GET_STATISTICS_ASYNC_DATA_SUCCESS,
  QUESTIONS_GET_STATISTICS_ASYNC_DATA_FAILED,
  QUESTIONS_GET_STATISTICS_PROGRESS,
  QUESTIONS_GET_STATISTICS_SUCCESS,
  QUESTIONS_GET_STATISTICS_FAILED,
  QUESTIONS_CLEAR_STATE,
  QUESTIONS_CLEAR_FLASH_MESSAGE,
} from '../actions/questionsAction';

const initialState = {
  loadListStatus: '', // progress/success/failed
  loadActiveStatus: '', // progress/success/failed
  submitStatus: '', // progress/success/failed
  asyncStatus: '', // progress/success/failed
  asyncRefreshStatus: '', // progress/success/failed
  editTimeStatus: '', // progress/success/failed
  justSave: false,
  isDelete: false,
  isSendToTechnical: false,
  closeQuestion: false,
  list: [],
  pagination: {},
  active: {},
  asyncData: {},
  flash: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case QUESTIONS_GET_LIST_PROGRESS: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: QUESTIONS_PROGRESS },
        },
      );
    }
    case QUESTIONS_GET_LIST_SUCCESS: {
      return update(
        state, {
          list: { $set: action.payload.list },
          pagination: { $set: action.payload.pagination },
          loadListStatus: { $set: QUESTIONS_SUCCESS },
        },
      );
    }
    case QUESTIONS_GET_LIST_FAILED: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: QUESTIONS_FAILED },
        },
      );
    }
    case QUESTIONS_FORM_SUBMIT_PROGRESS: {
      return update(
        state,
        { submitStatus: { $set: QUESTIONS_PROGRESS } },
      );
    }
    case QUESTIONS_FORM_SUBMIT_SUCCESS: {
      return update(
        state,
        {
          flash: { $set: action.payload },
          submitStatus: { $set: QUESTIONS_SUCCESS },
        },
      );
    }
    case QUESTIONS_FORM_SUBMIT_FAILED: {
      return update(
        state,
        { submitStatus: { $set: QUESTIONS_FAILED } },
      );
    }
    case QUESTIONS_GET_QUESTION_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: QUESTIONS_PROGRESS },
        },
      );
    }
    case QUESTIONS_GET_QUESTION_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.question },
          loadActiveStatus: { $set: QUESTIONS_SUCCESS },
        },
      );
    }
    case QUESTIONS_GET_QUESTION_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: QUESTIONS_FAILED },
        },
      );
    }
    case QUESTIONS_GET_ASYNC_DATA_PROGRESS: {
      return update(
        state, {
          asyncData: { $set: {} },
          asyncStatus: { $set: QUESTIONS_PROGRESS },
        },
      );
    }
    case QUESTIONS_GET_ASYNC_DATA_SUCCESS: {
      return update(
        state, {
          asyncData: { $set: action.payload },
          asyncStatus: { $set: QUESTIONS_SUCCESS },
        },
      );
    }
    case QUESTIONS_GET_ASYNC_DATA_FAILED: {
      return update(
        state, {
          asyncData: { $set: {} },
          asyncStatus: { $set: QUESTIONS_FAILED },
        },
      );
    }
    case QUESTIONS_REFRESH_ASYNC_DATA_PROGRESS: {
      return update(
        state, {
          asyncRefreshStatus: { $set: QUESTIONS_PROGRESS },
        },
      );
    }
    case QUESTIONS_REFRESH_ASYNC_DATA_SUCCESS: {
      return update(
        state, {
          asyncData: { tags: { $set: action.payload.tags } },
          asyncRefreshStatus: { $set: QUESTIONS_SUCCESS },
        },
      );
    }
    case QUESTIONS_REFRESH_ASYNC_DATA_FAILED: {
      return update(
        state, {
          asyncRefreshStatus: { $set: QUESTIONS_FAILED },
        },
      );
    }
    case QUESTIONS_UPDATE_EDIT_TIME_PROGRESS: {
      return update(
        state, {
          editTimeStatus: { $set: QUESTIONS_PROGRESS },
        },
      );
    }
    case QUESTIONS_UPDATE_EDIT_TIME_SUCCESS: {
      return update(
        state, {
          editTimeStatus: { $set: QUESTIONS_SUCCESS },
        },
      );
    }
    case QUESTIONS_UPDATE_EDIT_TIME_FAILED: {
      return update(
        state, {
          editTimeStatus: { $set: QUESTIONS_FAILED },
        },
      );
    }
    case QUESTIONS_SET_JUST_SAVE: {
      return update(
        state, {
          justSave: { $set: true },
        },
      );
    }
    case QUESTIONS_DELETE: {
      return update(
        state, {
          isDelete: { $set: true },
        },
      );
    }
    case QUESTIONS_SEND_TO_TECHNICAL: {
      return update(
        state, {
          isSendToTechnical: { $set: true },
        },
      );
    }
    case QUESTIONS_CLOSE_QUESTION: {
      return update(
        state, {
          closeQuestion: { $set: true },
        },
      );
    }
    case QUESTIONS_GET_STATISTICS_ASYNC_DATA_PROGRESS: {
      return update(
        state, {
          asyncStatus: { $set: QUESTIONS_PROGRESS },
        },
      );
    }
    case QUESTIONS_GET_STATISTICS_ASYNC_DATA_SUCCESS: {
      return update(
        state, {
          asyncData: { curators: { $set: action.payload.curators } },
          asyncStatus: { $set: QUESTIONS_SUCCESS },
        },
      );
    }
    case QUESTIONS_GET_STATISTICS_ASYNC_DATA_FAILED: {
      return update(
        state, {
          asyncStatus: { $set: QUESTIONS_FAILED },
        },
      );
    }
    case QUESTIONS_GET_STATISTICS_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: QUESTIONS_PROGRESS },
        },
      );
    }
    case QUESTIONS_GET_STATISTICS_SUCCESS: {
      return update(
        state, {
          active: { $set: { ...action.payload } },
          loadActiveStatus: { $set: QUESTIONS_SUCCESS },
        },
      );
    }
    case QUESTIONS_GET_STATISTICS_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: QUESTIONS_FAILED },
        },
      );
    }
    case QUESTIONS_CLEAR_STATE: {
      return update(
        state,
        {
          loadListStatus: { $set: '' },
          loadActiveStatus: { $set: '' },
          submitStatus: { $set: '' },
          asyncStatus: { $set: '' },
          asyncRefreshStatus: { $set: '' },
          editTimeStatus: { $set: '' },
          justSave: { $set: false },
          isDelete: { $set: false },
          isSendToTechnical: { $set: false },
          closeQuestion: { $set: false },
          list: { $set: [] },
          pagination: { $set: {} },
          active: { $set: {} },
          asyncData: { $set: {} },
        },
      );
    }
    case QUESTIONS_CLEAR_FLASH_MESSAGE: {
      return update(
        state, {
          flash: { $set: '' },
        },
      );
    }
    default:
      return state;
  }
}
