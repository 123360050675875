import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { FieldArray, reduxForm, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import RenderMaterials from '../../components/RenderMaterials';
import store from '../../../App/store';
import validate from '../../validate';

const originalTheme = {
  width: '100%',
  display: 'flex',
  backgroundColor: '#70bbfd',
  color: '#ffffff',
  padding: '10px',
  marginBottom: '10px',
};

class WizardFormSix extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    asyncData: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor() {
    super();
    this.state = {
      flowMaterials: [],
      dataComplete: false,
    };
  }

  componentDidMount() {
    const selector = formValueSelector('flows_create_form');
    const themes = selector(store.getState(), 'themes');
    const { asyncData } = this.props;
    const { materials } = asyncData;
    const flowMaterials = [];
    const map = {};

    for (let i = 0; i < themes.length; i += 1) {
      for (let j = 0; j < themes[i].materials.length; j += 1) {
        map[themes[i].materials[j]] = null;
      }
    }

    const mapLength = Object.keys(map).length;
    let materialsInMapCount = 0;

    for (let i = 0; i < materials.length; i += 1) {
      if (materials[i].value in map) {
        map[materials[i].value] = materials[i];
        materialsInMapCount += 1;

        if (mapLength === materialsInMapCount) {
          break;
        }
      }
    }

    for (let i = 0; i < themes.length; i += 1) {
      const themeMaterials = [];

      for (let j = 0; j < themes[i].materials.length; j += 1) {
        themeMaterials.push({
          ...map[themes[i].materials[j]],
          importance: { value: 'required', label: 'Обязательно' },
        });
      }

      flowMaterials.push({
        id: themes[i].id,
        name: 'name' in themes[i] ? themes[i].name : themes[i].original,
        materials: themeMaterials,
      });
    }

    this.setState({
      flowMaterials, dataComplete: true,
    });
  }

  render() {
    const { handleSubmit, previousPage } = this.props;
    const { flowMaterials, dataComplete } = this.state;

    return (
      <form className="form form--horizontal wizard__form wizard__form__lg" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Определите важность материалов курса</h3>
        {dataComplete && (
          flowMaterials.map(theme => (
            <div key={theme.id} className="form__form-group">
              <div style={originalTheme}>
                <strong>ТЕМА:</strong>&nbsp; {theme.name}
              </div>
              <FieldArray
                name={`materials[${theme.id}]`}
                component={RenderMaterials}
                props={{ materials: theme.materials }}
              />
            </div>
          ))
        )}
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}>Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next">Следующий шаг</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'flows_create_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(WizardFormSix);
