import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../ProtectedRoute';

import PageTablePage from '../../../Pages/PageTable/index';
import PageCreatePage from '../../../Pages/PageCreate/index';
import PageEditPage from '../../../Pages/PageEdit/index';

export default () => (
  <Switch>
    <ProtectedRoute path="/pages" exact component={PageTablePage} />
    <ProtectedRoute path="/pages/create" component={PageCreatePage} />
    <ProtectedRoute path="/pages/:pageId" exact component={PageEditPage} />
  </Switch>
);
