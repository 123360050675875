import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { Field, formValueSelector } from 'redux-form';
import moment from 'moment';
import 'moment/locale/ru';
import store from '../../App/store';
import renderDatePickerField from '../../../shared/components/form/DatePicker';
import {
  PROLONGATION_SUCCESS,
  changeProlongation,
  activateCourse as activateCourseAction,
} from '../../../redux/actions/prolongationAction';
import { BasicNotification } from '../../../shared/components/Notification';
import Modal from '../../../shared/components/Modal';

const hr = {
  borderTop: '1px solid #cecece',
  paddingTop: '15px',
};

class RenderProlongation extends PureComponent {
  static propTypes = {
    fields: PropTypes.objectOf(PropTypes.any).isRequired,
    meta: PropTypes.objectOf(PropTypes.any).isRequired,
    options: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
    activateCourse: PropTypes.func.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    const { options } = nextProps;
    const { activateStatus } = options;

    if (activateStatus === PROLONGATION_SUCCESS) {
      this.alert('success', 'Активирован', 'Курс был успешно активирован');
    }
  }

  alert = (color, title, message = undefined) => {
    const { options } = this.props;
    const { showNotification } = options;

    return showNotification({
      notification: <BasicNotification color={color} title={title} message={message} />,
      position: 'right-up',
    });
  };

  getDate = date => (date !== null
    ? moment(date).locale('ru').format('LL')
    : 'Курс еще не активирован пользователем');

  changeDates = (flow, index) => {
    const { options } = this.props;
    const { userId } = options;
    const selector = formValueSelector('prolongation_edit_form');
    const prolongations = selector(store.getState(), 'prolongation');
    const { adminAvailableDate, adminQuestionDate } = prolongations[index];

    changeProlongation(userId, flow, adminAvailableDate, adminQuestionDate)
      .then(() => this.alert('success', 'Сохранено', 'Даты были успешно обновлены'))
      .catch(() => this.alert('danger', 'Произошла ошибка', 'Попробуйте еще раз'));
  };

  activateCourse = (flow) => {
    const { options, activateCourse } = this.props;
    const { userId } = options;

    activateCourse(userId, flow);
  };

  isActiveCourse = (course) => {
    const { isUserActivate, startDate } = course;
    return !(!isUserActivate || startDate === null || Date.parse(startDate) > Date.now());
  }

  render() {
    const { fields, meta: { error, submitFailed } } = this.props;

    return (
      <div className="form__form-group">
        {submitFailed && error
          && (
            <div className="form__form-group">
              <div>
                <span className="form__form-group-error">{error}</span>
              </div>
            </div>
          )}
        {fields.map((prolongation, index) => (
          <div key={fields.get(index).id} className="form__form-group" style={hr}>
            <div>
              <span className="form__form-group-label">
                <strong>Название {fields.get(index).type === 'course' ? 'курса' : 'марафона'}: </strong>
                {fields.get(index).name}
              </span>
            </div>
            {!this.isActiveCourse(fields.get(index)) ? (
              <div>
                <div>
                  <span className="form__form-group-label">
                    <strong>Дата старта:</strong> {this.getDate(fields.get(index).startDate)}
                  </span>
                </div>
                <div>
                  <span className="form__form-group-label">
                    <strong>Статус:</strong> Курс еще не активирован пользователем
                  </span>
                </div>
                <div className="mt-3">
                  <Modal
                    color="primary"
                    title="Пожалуйста подтвердите"
                    header
                    btn="Активировать"
                    message="Вы уверены, что хотите активировать этот курс?"
                    success={() => this.activateCourse(fields.get(index).id)}
                  />
                </div>
              </div>
            ) : (
              <Fragment>
                <Row>
                  <Col md={12}>
                    <div>
                      <span className="form__form-group-label">
                        <strong>Дата старта:</strong> {this.getDate(fields.get(index).startDate)}
                      </span>
                    </div>
                    <div>
                      <span className="form__form-group-label">
                        <strong>Доступен до:</strong> {this.getDate(fields.get(index).availableDate)}
                      </span>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                      <span className="form__form-group-label">
                        <strong>Возможность задавать вопросы доступна до: </strong>
                        {this.getDate(fields.get(index).availableQuestionDate)}
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {fields.get(index).type === 'course' ? 'Курс' : 'Марафон'} доступен до
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name={`${prolongation}.adminAvailableDate`}
                          component={renderDatePickerField}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Возможность задавать вопросы, доступна до</span>
                      <div className="form__form-group-field">
                        <Field
                          name={`${prolongation}.adminQuestionDate`}
                          component={renderDatePickerField}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <span className="form__form-group-label">&nbsp;</span>
                    <div className="form__form-group-field">
                      <Button
                        className="mb-0"
                        color="primary"
                        size="sm"
                        type="button"
                        onClick={() => this.changeDates(fields.get(index).id, index)}
                      >
                        Сохранить
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            )}
          </div>
        ))}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  activateCourse: (userId, flowId) => dispatch(activateCourseAction(userId, flowId)),
});

export default connect(
  null,
  mapDispatchToProps,
)(RenderProlongation);
