import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { createTheme as createThemeAction } from '../../../../redux/actions/themesAction';
import renderSketchColorPickerField from '../../../../shared/components/form/SketchColorPicker';

class WizardFormNine extends PureComponent {
  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit, previousPage, submitting } = this.props;

    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Стилизуйте цветовую схему темы</h3>
        <div className="form__form-group">
          <span className="form__form-group-label">Цвет фона</span>
          <div className="form__form-group-field">
            <Field
              name="backgroundColor"
              component={renderSketchColorPickerField}
              hex="56c3e0"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Цвет названия темы</span>
          <div className="form__form-group-field">
            <Field
              name="themeName"
              component={renderSketchColorPickerField}
              hex="ffffff"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Цвет фона названия курса</span>
          <div className="form__form-group-field">
            <Field
              name="themeNameBackground"
              component={renderSketchColorPickerField}
              hex="56c3e0"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Цвет описания курса</span>
          <div className="form__form-group-field">
            <Field
              name="themeDescription"
              component={renderSketchColorPickerField}
              hex="222b3a"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Цвет подтемы</span>
          <div className="form__form-group-field">
            <Field
              name="themeSub"
              component={renderSketchColorPickerField}
              hex="ffffff"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Цвет фона названия подтемы</span>
          <div className="form__form-group-field">
            <Field
              name="themeSubBackground"
              component={renderSketchColorPickerField}
              hex="222b3a"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Цвет прогресс бара</span>
          <div className="form__form-group-field">
            <Field
              name="courseProgress"
              component={renderSketchColorPickerField}
              hex="0795d5"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Цвет статистики</span>
          <div className="form__form-group-field">
            <Field
              name="courseProgressCaptions"
              component={renderSketchColorPickerField}
              hex="222b3a"
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}>Предыдущий шаг</Button>
          <Button color="primary" type="submit" className="next" disabled={submitting}>Создать новую тему</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'themes_create_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: createThemeAction,
})(WizardFormNine);
